import * as React from 'react'
import { validate as uuidValidate } from 'uuid'

import Profile from 'profile/profile-component'
import PendingSubmissions from 'pending-submissions'
import JobsList from 'job/job-list-component'
import Drafts from 'drafts/drafts-component'
import FormList from 'form-list'
import ScheduledTasksScene from 'scheduled-tasks/ScheduledTasksScene'
import ScheduledTaskGroupsInstanceScene from 'scheduled-task-group-instances/ScheduledTaskGroupInstancesScene'
import { FormsAppsTypes } from '@oneblink/types'
import CPHCMSContentInstanceScene from 'cp-hcms-content/CPHCMSContentInstanceScene'

export type MenuItem = FormsAppsTypes.FormsAppMenuItem & {
  classSuffix: string | null
  routeComponent: React.ReactNode | null
  href: string
  defaultHref: string
  badge?: number
}

export const formsListHref = '/forms-list'
export const scheduledTasksGroupsHref = '/scheduled-task-groups'
export const pendingHref = '/pending'

const isTileApp = window.formsHostnameConfiguration?.type === 'TILES'

export const menuItems: Array<MenuItem> = (
  window.formsHostnameConfiguration?.styles?.menuItems || []
)
  .filter((menuItem) => {
    if (
      menuItem.type !== 'HREF' &&
      menuItem.type !== 'FORM' &&
      menuItem.type !== 'CONTAINER' &&
      menuItem.type !== 'SCHEDULED_TASKS' &&
      menuItem.type !== 'SCHEDULED_TASK_GROUPS' &&
      menuItem.type !== 'CP_HCMS_CONTENT' &&
      menuItem.isHidden
    ) {
      return false
    }

    // remove forms list if we are a tiles app
    if (isTileApp && menuItem.type === 'FORMS_LIST') {
      return false
    }

    // Remove drafts from menu if it is not enabled
    if (
      menuItem.type === 'DRAFTS' &&
      !window.formsHostnameConfiguration?.isDraftsEnabled
    ) {
      return false
    }

    // TODO
    // Remove this once we support logins for native apps
    if (
      window.cordova &&
      (menuItem.type === 'DRAFTS' ||
        menuItem.type === 'JOBS' ||
        menuItem.type === 'PROFILE')
    ) {
      return false
    }

    return true
  })
  .map((menuItem) => {
    // Default to the
    let href = '/'
    let classSuffix = null
    let routeComponent = null
    switch (menuItem.type) {
      case 'FORMS_LIST': {
        href = formsListHref
        classSuffix = 'home'
        routeComponent = <FormList />
        break
      }
      case 'JOBS': {
        href = '/jobs'
        classSuffix = 'jobs'
        routeComponent = <JobsList />
        break
      }
      case 'PENDING_SUBMISSIONS': {
        href = pendingHref
        classSuffix = 'pending'
        routeComponent = <PendingSubmissions />
        break
      }
      case 'DRAFTS': {
        href = '/drafts'
        classSuffix = 'drafts'
        routeComponent = <Drafts />
        break
      }
      case 'PROFILE': {
        href = '/profile'
        classSuffix = 'profile'
        routeComponent = <Profile />
        break
      }
      case 'HREF': {
        href = menuItem.href
        classSuffix = `link ${menuItem.label.replace(/\s+/g, '')}`
        break
      }
      case 'CONTAINER': {
        href = formsListHref
        routeComponent = <FormList />
        classSuffix = 'tile_container'
        break
      }
      case 'FORM': {
        href = `/forms/${menuItem.formId}`
        classSuffix = 'form'
        break
      }
      case 'SCHEDULED_TASKS': {
        href = '/scheduled-tasks'
        routeComponent = (
          <ScheduledTasksScene
            title={menuItem.label}
            taskGroupInstanceId={undefined}
          />
        )
        classSuffix = 'scheduled_tasks'
        break
      }
      case 'SCHEDULED_TASK_GROUPS': {
        href = scheduledTasksGroupsHref
        routeComponent = <ScheduledTaskGroupsInstanceScene />
        classSuffix = 'scheduled_tasks'
        break
      }
      case 'CP_HCMS_CONTENT': {
        href = `/forms/${menuItem.formId}/hcms-content`
        routeComponent = <CPHCMSContentInstanceScene menuItem={menuItem} />
        classSuffix = 'hcms_content'
        break
      }
      default: {
        // Unknown type of menu item...
      }
    }
    return {
      ...menuItem,
      routeComponent,
      classSuffix,
      defaultHref: href,
      href:
        menuItem.type !== 'HREF' &&
        menuItem.type !== 'FORM' &&
        menuItem.type !== 'CONTAINER' &&
        menuItem.isDefault
          ? '/'
          : href,
    }
  })

// Ensure there is a default menu item
if (window.formsHostnameConfiguration?.type !== 'TILES') {
  if (
    !menuItems.some(
      (menuItem) =>
        menuItem.type !== 'HREF' &&
        menuItem.type !== 'FORM' &&
        menuItem.type !== 'CONTAINER' &&
        menuItem.isDefault,
    )
  ) {
    const menuItem = menuItems.find(
      (menuItem) =>
        menuItem.type !== 'HREF' &&
        menuItem.type !== 'FORM' &&
        menuItem.type !== 'CONTAINER',
    )
    if (
      menuItem &&
      menuItem.type !== 'HREF' &&
      menuItem.type !== 'FORM' &&
      menuItem.type !== 'CONTAINER'
    ) {
      menuItem.isDefault = true
      menuItem.href = '/'
    }
  }
}

function getMenuItem(type: FormsAppsTypes.FormsAppMenuItem['type']) {
  return menuItems.find(
    (menuItem) =>
      menuItem.type === type &&
      // @ts-expect-error we don't care if this property is missing typescript!
      !menuItem.isHidden,
  )
}

function getMenuItemLabel(type: FormsAppsTypes.FormsAppMenuItem['type']) {
  const menuItem = getMenuItem(type)
  return menuItem?.label
}

export function isEditingHCMSContent(
  formId: number,
  externalId: string | (string | null)[] | null,
) {
  const cpHCMSMenuItem = getCPHCMSContentMenuItem(formId)
  if (
    cpHCMSMenuItem &&
    typeof externalId === 'string' &&
    uuidValidate(externalId)
  ) {
    return cpHCMSMenuItem
  }
}

const pendingSubmissionsMenuItem = getMenuItem('PENDING_SUBMISSIONS')

export const formsListLabel = getMenuItemLabel('FORMS_LIST') || 'Forms'
export const jobsLabel = getMenuItemLabel('JOBS') || 'Jobs'
export const draftsLabel = getMenuItemLabel('DRAFTS') || 'Drafts'
export const pendingSubmissionsLabel =
  pendingSubmissionsMenuItem?.label || 'Pending Submissions'
export const profileMenuItem = getMenuItem('PROFILE')
export const profileLabel = profileMenuItem?.label || 'Profile'
export const scheduledTasksMenuItem = getMenuItem('SCHEDULED_TASKS')
export const scheduledTasksLabel =
  scheduledTasksMenuItem?.label || 'Scheduled Tasks'
export const scheduledTaskGroupsMenuItem = getMenuItem('SCHEDULED_TASK_GROUPS')
export const scheduledTaskGroupsLabel =
  scheduledTaskGroupsMenuItem?.label || 'Scheduled Tasks'
export const isPendingQueueEnabled = !!pendingSubmissionsMenuItem
export const scheduledTaskGroupInstances =
  window.formsHostnameConfiguration?.taskGroupInstances

export function getCPHCMSContentMenuItem(formId: number) {
  return menuItems.find(
    (menuItem) =>
      menuItem.type === 'CP_HCMS_CONTENT' && menuItem.formId === formId,
  )
}
