import * as React from 'react'
import { draftService, localisationService } from '@oneblink/apps'
import { useIsMounted, useDrafts, useBooleanState } from '@oneblink/apps-react'
import { userService } from '@oneblink/sdk-core'

import { useHistory } from 'react-router-dom'
import { Modal, ErrorModal, OnLoading, MaterialIcon } from 'components'
import { SubmissionTypes } from '@oneblink/types'
import generateResumeDraftUrl from 'services/generate-resume-draft-url'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import { Clickable } from 'components/Clickable'

type Props = {
  isDisplayingJobKeyColumn: boolean
  formSubmissionDraftId: string | undefined
  draft: draftService.LocalFormSubmissionDraft & {
    jobData: SubmissionTypes.FormsAppJob | null
  }
}

function DraftListItem({
  draft,
  formSubmissionDraftId,
  isDisplayingJobKeyColumn,
}: Props) {
  const isMounted = useIsMounted()
  const history = useHistory()
  const { deleteDraft } = useDrafts()

  const sharedDrafts = React.useMemo(
    () => !!window.formsHostnameConfiguration?.draftsAreShared,
    [],
  )

  const [{ isDeleting, isConfirming, error }, setState] = React.useState<{
    isDeleting: boolean
    isConfirming: boolean
    error: Error | null
  }>({
    isDeleting: false,
    isConfirming: false,
    error: null,
  })

  const clearError = React.useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      error: null,
    }))
  }, [])

  const handleDeleteDraft = React.useCallback(
    async (formSubmissionDraftId: string) => {
      if (isMounted.current) {
        setState({
          isConfirming: true,
          isDeleting: true,
          error: null,
        })
      }

      let newError = null

      try {
        await deleteDraft(formSubmissionDraftId)
      } catch (error) {
        newError = error as Error
      }

      if (isMounted.current) {
        setState({
          isConfirming: true,
          isDeleting: false,
          error: newError,
        })
      }
    },
    [deleteDraft, isMounted],
  )

  const confirmDelete = React.useCallback((e) => {
    e.stopPropagation()
    setState({
      isConfirming: true,
      isDeleting: false,
      error: null,
    })
  }, [])

  const [
    isShowingNoDraftDataModal,
    showNoDraftDataModal,
    hideNoDraftDataModal,
  ] = useBooleanState(false)

  const resumeDraft = React.useCallback(() => {
    const url = generateResumeDraftUrl({
      draft,
    })
    if (url) {
      console.log('resuming draft', url)
      history.push(url)
    } else {
      showNoDraftDataModal()
    }
  }, [draft, history, showNoDraftDataModal])

  const firstVersion = draft.versions?.[0]
  const latestVersion = React.useMemo(() => {
    return draftService.getLatestFormSubmissionDraftVersion(draft.versions)
  }, [draft.versions])

  const lastSavedAt =
    latestVersion?.createdAt || draft.draftSubmission?.createdAt

  return (
    <>
      <Clickable
        className="ob-list__item cypress-draft-list-item"
        onClick={resumeDraft}
      >
        <div className="ob-list__content-wrapper">
          {isDisplayingJobKeyColumn &&
            draft.jobData &&
            !!draft.jobData.details.key && (
              <div className="ob-list__key ob-list__job-key">
                {draft.jobData.details.key}
              </div>
            )}
          <div className="ob-list__content">
            <div className="ob-list__text-primary ob-list__draft-title">
              {draft.draftSubmission?.title || latestVersion?.title}{' '}
              {draft.jobData && (
                <span className="ob-list__job-title">
                  ({draft.jobData.details.title})
                </span>
              )}
            </div>
            <Grid container className="ob-list__text-secondary">
              <DraftDetailGrid
                className="ob-list__draft-timestamp"
                title="Last Saved"
                value={
                  lastSavedAt
                    ? localisationService.formatDatetime(new Date(lastSavedAt))
                    : '-'
                }
              />

              {sharedDrafts && firstVersion?.createdBy.user && (
                <DraftDetailGrid
                  className="ob-list__draft-created-by"
                  title="Created By"
                  value={userService.getUserFriendlyName(
                    firstVersion?.createdBy.user,
                  )}
                />
              )}
              {sharedDrafts && latestVersion?.createdBy.user && (
                <DraftDetailGrid
                  className="ob-list__draft-updated-by"
                  title="Last Updated By"
                  value={userService.getUserFriendlyName(
                    latestVersion?.createdBy.user,
                  )}
                />
              )}
              {draft.jobData && (
                <DraftDetailGrid
                  className="ob-list__draft-job-timestamp job-timestamp"
                  title="Assigned"
                  value={localisationService.formatDatetime(
                    new Date(draft.jobData.createdAt),
                  )}
                />
              )}
              {draft.draftSubmission?.taskCompletion?.task.name && (
                <DraftDetailGrid
                  className="ob-list__draft-task-name task-name"
                  title="Task Name"
                  value={draft.draftSubmission?.taskCompletion.task.name}
                />
              )}
            </Grid>
            {draft.jobData && !!draft.jobData.details.description && (
              <div className="ob-list__text-tertiary ob-list__draft-job-description">
                {draft.jobData.details.description}
              </div>
            )}
          </div>
        </div>

        <div className="ob-list__actions">
          {!draft.versions && (
            <div className="ob-list__job-tag cypress-draft-chip">
              <span className="tag is-info">Unsynced</span>
            </div>
          )}
          <button
            type="button"
            className="button ob-button ob-list__button is-inverted is-black tooltip has-tooltip-left cypress-delete-draft"
            onClick={confirmDelete}
            data-tooltip="Delete Draft"
          >
            <span className="icon is-small">
              <MaterialIcon
                className="ob-drafts__icon ob-icon__delete"
                aria-hidden={false}
              >
                delete
              </MaterialIcon>
            </span>
          </button>
        </div>
      </Clickable>

      {formSubmissionDraftId && (
        <Modal
          isOpen={isConfirming}
          title="Delete Draft"
          titleClassName="ob-title__deleting"
          actions={
            <>
              <button
                type="button"
                className="button ob-button is-light ob-button__cancel"
                disabled={isDeleting}
                onClick={() =>
                  setState((currentState) => ({
                    ...currentState,
                    isConfirming: false,
                  }))
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className="button ob-button is-primary ob-button__delete cypress-confirm-draft-delete"
                disabled={isDeleting}
                onClick={() => handleDeleteDraft(formSubmissionDraftId)}
                autoFocus
              >
                Delete
              </button>
            </>
          }
        >
          {isDeleting ? (
            <div className="has-text-centered">
              <OnLoading className="has-text-centered" />
            </div>
          ) : (
            <p>Do you wish to delete this draft?</p>
          )}
        </Modal>
      )}

      <Modal
        isOpen={isShowingNoDraftDataModal}
        title="Missing Draft Data"
        titleClassName="ob-title__missing-draft-data"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-primary ob-button__okay cypress-missing-draft-data-okay-button"
              onClick={hideNoDraftDataModal}
              autoFocus
            >
              Okay
            </button>
          </>
        }
      >
        <p>
          You do not have a local copy of this draft&apos;s data and you have
          not yet retrieved the latest version. Please ensure you are online and
          press the sync drafts button to download the draft data.
        </p>
        <p>
          If syncing your drafts does not retrieve the latest version, this
          draft&apos;s data has been removed based on your administrator&apos;s
          draft data retention policy.
        </p>
      </Modal>

      <ErrorModal error={error} onClose={clearError} />
    </>
  )
}

export default React.memo<Props>(DraftListItem)

const DraftDetailGrid = ({
  className,
  title,
  value,
}: {
  className: string
  title: string
  value: string
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      className={clsx(className, 'ob-list__draft-detail')}
    >
      <div className="ob-list__draft-detail-title">{title}</div>
      <div className="ob-list__draft-detail-value">{value}</div>
    </Grid>
  )
}
