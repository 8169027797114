import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { authService } from '@oneblink/apps'

import { OnLoading, ErrorModal } from 'components'

function Callback() {
  const history = useHistory()
  const [error, setError] = React.useState(null)
  const handleCloseError = React.useCallback(() => {
    history.replace('/')
  }, [history])

  React.useEffect(() => {
    authService
      .handleAuthentication()
      .then((continueTo) => {
        console.log('Transitioning to continue to URL', continueTo)
        history.replace(continueTo)
      })
      .catch((error) => {
        console.warn('Error while attempting handle authentication', error)
        setError(error)
      })
  }, [history])

  return (
    <section className="section ob-authentication">
      <ErrorModal error={error} onClose={handleCloseError} />
      {!error && (
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
          <span>Finalising Login</span>
        </div>
      )}
    </section>
  )
}

export default React.memo(Callback)
