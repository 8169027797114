import * as React from 'react'
import { scheduledTasksService } from '@oneblink/apps'
import { useLoadDataState } from '@oneblink/apps-react'
import { scheduledTaskGroupsMenuItem } from 'services/menu-items-service'

export default function useScheduledTaskGroupInstances(formsAppId: number) {
  const loadScheduledTasks = React.useCallback(
    async (abortSignal: AbortSignal) => {
      if (!scheduledTaskGroupsMenuItem) {
        return { taskGroupInstances: [] }
      }
      return await scheduledTasksService.getTaskGroupInstances(
        formsAppId,
        abortSignal,
      )
    },
    [formsAppId],
  )
  return useLoadDataState(loadScheduledTasks)
}
