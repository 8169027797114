import * as React from 'react'
import { localisationService } from '@oneblink/apps'
import { submissionService, authService } from '@oneblink/apps'
import sanitizeHtml from '@oneblink/apps-react/dist/services/sanitize-html'

function FormPostSubmissionReceipt({
  postSubmissionReceiptHtml,
  formSubmissionResult,
}: {
  postSubmissionReceiptHtml: string
  formSubmissionResult: submissionService.FormSubmissionResult
}) {
  const sanitizedHtml = React.useMemo(() => {
    const html = localisationService.replaceInjectablesWithSubmissionValues(
      postSubmissionReceiptHtml,
      {
        previousApprovalId:
          formSubmissionResult.previousFormSubmissionApprovalId,
        form: formSubmissionResult.definition,
        submission: formSubmissionResult.submission,
        submissionId: formSubmissionResult.submissionId || '',
        submissionTimestamp: formSubmissionResult.submissionTimestamp || '',
        externalId: formSubmissionResult.externalId || undefined,
        userProfile: authService.getUserProfile() || undefined,
        task: formSubmissionResult.taskCompletion?.task,
        taskGroup: formSubmissionResult.taskCompletion?.taskGroup,
        taskGroupInstance:
          formSubmissionResult.taskCompletion?.taskGroupInstance,
      },
    ).text

    return sanitizeHtml(html)
  }, [formSubmissionResult, postSubmissionReceiptHtml])

  return (
    <div
      className="cypress-submission-success-receipt-content ob-submission-success__receipt-content ql-editor"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  )
}

export default React.memo(FormPostSubmissionReceipt)
