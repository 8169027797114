import * as React from 'react'
import clsx from 'clsx'
import { Avatar, Chip } from '@mui/material'
import { userService } from '@oneblink/sdk-core'
import { ScheduledTasksTypes } from '@oneblink/types'
import { MaterialIcon } from 'components'

function CompletedByChip({
  className,
  completedTask,
}: {
  className?: string
  completedTask: ScheduledTasksTypes.CompletedTask
}) {
  if (!completedTask.completedBy) {
    return null
  }

  return (
    <Chip
      className={clsx(
        'cypress-scheduled-task-chip-completed-by ob-completed-task__chip ob-completed-task__user-chip',
        className,
      )}
      variant="outlined"
      icon={
        completedTask.completedBy.picture ? (
          <Avatar
            src={completedTask.completedBy.picture}
            sx={{ width: 24, height: 24 }}
          />
        ) : (
          <MaterialIcon>account_circle</MaterialIcon>
        )
      }
      label={userService.getUserFriendlyName(completedTask.completedBy)}
      size="medium"
    />
  )
}

export default React.memo(CompletedByChip)
