import * as React from 'react'
import clsx from 'clsx'
import { Modal, ErrorModal } from 'components'

function CPHCMSDraftContentModal({
  handleCancelDraft,
  isConfirming,
  isDrafting,
  handleDraft,
  error,
  clearDraftError,
}: {
  handleCancelDraft: () => void
  handleDraft: () => void
  isConfirming: boolean
  isDrafting: boolean
  error: React.ComponentProps<typeof ErrorModal>['error']
  clearDraftError: () => void
}) {
  return (
    <>
      <Modal
        isOpen={isConfirming}
        title="Draft Content"
        titleClassName="ob-title__publishing"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-light ob-button__cancel"
              disabled={isDrafting}
              onClick={handleCancelDraft}
            >
              Cancel
            </button>
            <button
              type="button"
              className={clsx(
                'button ob-button is-primary ob-button__delete cypress-confirm-content-draft',
                { 'is-loading': isDrafting },
              )}
              disabled={isDrafting}
              onClick={handleDraft}
              autoFocus
            >
              Draft
            </button>
          </>
        }
      >
        <p>Do you wish to draft this content?</p>
      </Modal>

      <ErrorModal error={error} onClose={clearDraftError} />
    </>
  )
}

export default React.memo(CPHCMSDraftContentModal)
