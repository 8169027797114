import React from 'react'
import LoginButton from './LoginButton'
import SignUpButton from './SignUpButton'

const isAppUserSignUpEnabled =
  window.formsHostnameConfiguration?.isAppUserSignUpEnabled

export default function LoginSignupButtons() {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <LoginButton />
      {isAppUserSignUpEnabled && <SignUpButton />}
    </div>
  )
}
