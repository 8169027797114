import * as React from 'react'
import { formService } from '@oneblink/apps'
import { SUBMISSION_JSON_PROP } from './constants'
import { Tooltip } from '@mui/material'
import { MaterialIcon } from 'components'

function EditContentItemButton({
  contentItem,
  selectItem,
}: {
  contentItem: formService.CivicPlusHCMSContentItem
  selectItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
}) {
  const isEditable = !!contentItem.data[SUBMISSION_JSON_PROP]
  return (
    <Tooltip
      title={
        isEditable
          ? 'Edit Content'
          : 'This submission was not created via this application and cannot be edited'
      }
      arrow
    >
      <span>
        <button
          disabled={!isEditable}
          className="button ob-button ob-list__button is-inverted is-black cypress-cp-hcms-content-item-edit-button"
          onClick={() => selectItem(contentItem)}
        >
          <span className="icon is-small">
            <MaterialIcon
              className="ob-cp-hcms-content-items__icon ob-icon__edit-submission"
              aria-hidden={false}
            >
              edit
            </MaterialIcon>
          </span>
        </button>
      </span>
    </Tooltip>
  )
}

export default React.memo(EditContentItemButton)
