import { useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import { FormTypes } from '@oneblink/types'
import {
  draftsLabel,
  jobsLabel,
  pendingSubmissionsLabel,
  profileLabel,
  formsListLabel,
  scheduledTaskGroupInstances,
  scheduledTaskGroupsLabel,
  scheduledTasksLabel,
  scheduledTaskGroupsMenuItem,
  scheduledTasksMenuItem,
  formsListHref,
  scheduledTasksGroupsHref,
  getCPHCMSContentMenuItem,
  isEditingHCMSContent,
  pendingHref,
} from 'services/menu-items-service'
import useTaskGroupInstanceIdRoute from './useTaskGroupInstanceIdRoute'

type BreadCrumbItem = {
  name: string
  path?: {
    pathname: string
    search?: string
  }
}

const useBreadCrumbs = (form: FormTypes.Form | null): BreadCrumbItem[] => {
  const { pathname, search } = useLocation()
  const taskGroupInstanceIdRoute = useTaskGroupInstanceIdRoute()
  const formIdRoute = useRouteMatch<{ formIdPathParam: string }>(
    '/forms/:formIdPathParam',
  )
  const formPaymentReceiptRoute = useRouteMatch<{ formIdPathParam: string }>(
    '/forms/:formIdPathParam/payment-receipt',
  )
  const cpHcmsContentRoute = useRouteMatch<{ formIdPathParam: string }>(
    '/forms/:formIdPathParam/hcms-content',
  )

  const query = useMemo(() => queryString.parse(search), [search])

  const tile = useMemo(() => {
    if (typeof query.tile === 'string') {
      return query.tile
    }
  }, [query.tile])

  const parentTile = useMemo(() => {
    if (typeof query.parentTile === 'string') {
      return query.parentTile
    }
  }, [query.parentTile])

  const jobId = useMemo(() => {
    if (typeof query.jobId === 'string') {
      return query.jobId
    }
  }, [query.jobId])

  const draftId = useMemo(() => {
    if (typeof query.draftId === 'string') {
      return query.draftId
    }
  }, [query.draftId])

  const taskId = useMemo(() => {
    if (typeof query.taskId === 'string') {
      const taskIdInt = parseInt(query.taskId)
      return Number.isNaN(taskIdInt) ? undefined : taskIdInt
    }
  }, [query.taskId])

  const taskGroupInstanceQuery = useMemo(() => {
    if (typeof query.taskGroupInstanceId === 'string') {
      return scheduledTaskGroupInstances?.find(
        ({ taskGroupInstanceId }) =>
          taskGroupInstanceId === query.taskGroupInstanceId,
      )
    }
  }, [query.taskGroupInstanceId])

  const taskGroupInstancePath = useMemo(() => {
    if (taskGroupInstanceIdRoute?.params.taskGroupInstanceIdPathParam) {
      return scheduledTaskGroupInstances?.find(
        ({ taskGroupInstanceId }) =>
          taskGroupInstanceId ===
          taskGroupInstanceIdRoute.params.taskGroupInstanceIdPathParam,
      )
    }
  }, [taskGroupInstanceIdRoute?.params.taskGroupInstanceIdPathParam])

  return useMemo(() => {
    if (cpHcmsContentRoute?.params.formIdPathParam) {
      const formId = parseInt(cpHcmsContentRoute.params.formIdPathParam)
      const cpHCMSContentMenuItem = getCPHCMSContentMenuItem(formId)

      if (cpHCMSContentMenuItem) {
        return [
          {
            name: cpHCMSContentMenuItem?.label,
          },
        ]
      }
      return []
    }

    if (formPaymentReceiptRoute) {
      return [
        {
          name: 'Payment Receipt',
        },
      ]
    }

    // On Form pages, determine how user got there from query string
    if (formIdRoute?.params.formIdPathParam) {
      const formId = parseInt(formIdRoute.params.formIdPathParam)
      const cpHCMSMenuItem = isEditingHCMSContent(formId, query.externalId)
      const breadCrumbs: BreadCrumbItem[] = [
        {
          name: form && formId === form.id ? form.name : '',
        },
      ]
      if (taskGroupInstanceQuery && scheduledTaskGroupsMenuItem) {
        breadCrumbs.unshift(
          {
            name: scheduledTaskGroupsLabel,
            path: {
              pathname: scheduledTaskGroupsMenuItem.href,
            },
          },
          {
            name: taskGroupInstanceQuery.label,
            path: {
              pathname: `${scheduledTaskGroupsMenuItem.defaultHref}/${taskGroupInstanceQuery.taskGroupInstanceId}`,
            },
          },
        )
      } else if (taskId && scheduledTasksMenuItem) {
        breadCrumbs.unshift({
          name: scheduledTasksLabel,
          path: {
            pathname: scheduledTasksMenuItem.href,
          },
        })
      } else if (cpHCMSMenuItem) {
        breadCrumbs.unshift({
          name: cpHCMSMenuItem.label,
          path: {
            pathname: cpHCMSMenuItem.href,
          },
        })
      } else if (parentTile) {
        breadCrumbs.unshift({
          name: parentTile,
          path: {
            pathname: formsListHref,
            search: `?tile=${parentTile}`,
          },
        })
      } else if (jobId) {
        breadCrumbs.unshift({
          name: jobsLabel,
          path: {
            pathname: '/jobs',
          },
        })
      } else if (draftId) {
        breadCrumbs.unshift({
          name: draftsLabel,
          path: {
            pathname: '/drafts',
          },
        })
      }
      return breadCrumbs
    }

    if (taskGroupInstancePath && scheduledTaskGroupsMenuItem) {
      const breadCrumbs: BreadCrumbItem[] = [
        {
          name: scheduledTaskGroupsLabel,
          path: {
            pathname: scheduledTaskGroupsMenuItem.href,
          },
        },
        {
          name: taskGroupInstancePath.label,
        },
      ]
      return breadCrumbs
    }

    switch (pathname) {
      case formsListHref:
        return [
          {
            name: tile ? tile : formsListLabel,
          },
        ]
      case '/profile':
        return [
          {
            name: profileLabel,
          },
        ]
      case pendingHref:
        return [
          {
            name: pendingSubmissionsLabel,
          },
        ]
      case '/drafts':
        return [
          {
            name: draftsLabel,
          },
        ]
      case '/jobs':
        return [
          {
            name: jobsLabel,
          },
        ]
      case scheduledTasksMenuItem?.href:
        return [
          {
            name: scheduledTasksLabel,
          },
        ]
      case scheduledTasksGroupsHref:
        return [
          {
            name: scheduledTaskGroupsLabel,
          },
        ]
      default:
        return []
    }
  }, [
    cpHcmsContentRoute?.params.formIdPathParam,
    formPaymentReceiptRoute,
    formIdRoute?.params.formIdPathParam,
    taskGroupInstancePath,
    pathname,
    form,
    taskGroupInstanceQuery,
    taskId,
    query.externalId,
    parentTile,
    jobId,
    draftId,
    tile,
  ])
}

export default useBreadCrumbs
