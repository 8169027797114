import * as React from 'react'
import { ProgressBar } from '@oneblink/apps-react'

function PendingQueueProgressBar({
  title,
  progress,
  isEditing,
}: {
  title: string
  progress: number
  isEditing?: boolean
}) {
  const progressColor: React.ComponentProps<typeof ProgressBar>['color'] =
    React.useMemo(() => {
      if (!progress) return 'inherit'
      if (progress === 100) return 'success'
      return 'primary'
    }, [progress])
  return (
    <div className="ob-progress__pending-queue-wrapper">
      <div className="ob-list__text-secondary ob-progress__pending-queue-details">
        <span className="ob-progress__pending-queue-detail-title">{title}</span>
        {isEditing ? (
          <span className="ob-progress__pending-queue-editing">Editing</span>
        ) : (
          <span className="ob-progress__pending-queue-detail-progress">
            {!progress ? 'Pending' : `${progress}%`}
          </span>
        )}
      </div>
      <ProgressBar progress={progress} color={progressColor} />
    </div>
  )
}

export default React.memo(PendingQueueProgressBar)
