// @flow
'use strict'

import * as React from 'react'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation } from 'react-router-dom'

export default function useQuery(): ParsedQuery {
  const { search } = useLocation()
  return React.useMemo(() => queryString.parse(search), [search])
}
