import * as React from 'react'
import { HandleAction } from 'hooks/useScheduledTaskActions'
import CompletedScheduledTaskListItemContent from './ScheduledTaskListItemContent/CompletedScheduledTaskListItemContent'
import { TaskResponse } from 'hooks/useScheduledTasks'
import useIsShowingCompletedTasks from './useIsShowingCompletedTasks'
import { Collapse } from '@mui/material'
import ScheduledTaskSwipeableListItem from './ScheduledTaskSwipeableListItem'
import { ViewTaskActionSubmissionState } from './ScheduledTasksListContainer'

function ScheduledTaskTodayListItem({
  isSelected,
  taskResponse: {
    task,
    actions,
    completedTask,
    daysAvailable,
    isAnimatingTaskCompletion,
    isAnimatingTaskUndo,
  },
  onAction,
  onSelect,
  onAnimatingTaskRemovalFinished,
  setViewTaskActionSubmissionState,
}: {
  isSelected: boolean
  taskResponse: TaskResponse
  onAction: HandleAction
  onSelect: (taskId: string) => void
  onAnimatingTaskRemovalFinished: (taskId: string) => void
  setViewTaskActionSubmissionState: React.Dispatch<
    React.SetStateAction<ViewTaskActionSubmissionState>
  >
}) {
  const isShowingCompletedTasks = useIsShowingCompletedTasks()

  const isCollapsed = React.useMemo(() => {
    return (
      completedTask &&
      (isAnimatingTaskUndo ||
        isAnimatingTaskCompletion ||
        !isShowingCompletedTasks)
    )
  }, [
    completedTask,
    isAnimatingTaskCompletion,
    isAnimatingTaskUndo,
    isShowingCompletedTasks,
  ])

  return (
    <Collapse
      in={!isCollapsed}
      sx={{ width: '100%' }}
      unmountOnExit
      onExited={() => {
        onAnimatingTaskRemovalFinished(task.taskId)
      }}
    >
      {completedTask && (!isAnimatingTaskCompletion || isAnimatingTaskUndo) ? (
        <CompletedScheduledTaskListItemContent
          actions={actions}
          scheduledTask={task}
          completedTask={completedTask}
          daysAvailable={daysAvailable}
          isSelected={isSelected}
          onSelect={onSelect}
          setViewTaskActionSubmissionState={setViewTaskActionSubmissionState}
        />
      ) : (
        <ScheduledTaskSwipeableListItem
          isSelected={isSelected}
          actions={actions}
          scheduledTask={task}
          onSelect={onSelect}
          onAction={onAction}
          daysAvailable={daysAvailable}
        />
      )}
    </Collapse>
  )
}

export default React.memo(ScheduledTaskTodayListItem)
