import * as React from 'react'
import { draftService } from '@oneblink/apps'
import { useDrafts } from '@oneblink/apps-react'

export default function useFilteredDrafts() {
  const { syncDrafts } = useDrafts()

  const [{ filteredDrafts, isSyncingAndReloadingDrafts }, setFilteredDrafts] =
    React.useState<{
      isSyncingAndReloadingDrafts: boolean
      filteredDrafts: draftService.LocalFormSubmissionDraft[]
    }>({ filteredDrafts: [], isSyncingAndReloadingDrafts: false })

  const syncAndReloadDrafts = React.useCallback(async () => {
    try {
      await syncDrafts(undefined)
      return await draftService.getDrafts()
    } catch (e) {
      console.warn('Error syncing and/or reloading drafts - swallowing', e)
    }
    return []
  }, [syncDrafts])

  const filterDrafts = React.useCallback(
    async ({
      filter,
      ifNoDrafts,
    }: {
      filter: (draft: draftService.LocalFormSubmissionDraft) => boolean
      ifNoDrafts: () => void
    }) => {
      setFilteredDrafts({
        filteredDrafts: [],
        isSyncingAndReloadingDrafts: true,
      })
      const drafts = await syncAndReloadDrafts()
      const filteredDrafts = drafts.filter(filter)
      if (filteredDrafts.length) {
        setFilteredDrafts({
          filteredDrafts,
          isSyncingAndReloadingDrafts: false,
        })
      } else {
        return ifNoDrafts()
      }
    },
    [syncAndReloadDrafts],
  )

  const unfilterDrafts = React.useCallback(() => {
    setFilteredDrafts({
      filteredDrafts: [],
      isSyncingAndReloadingDrafts: false,
    })
  }, [])

  return {
    filteredDrafts,
    isSyncingAndReloadingDrafts,
    actions: {
      filterDrafts,
      unfilterDrafts,
    },
  }
}
