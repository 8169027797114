import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { submissionService } from '@oneblink/apps'
import { PaymentReceipt } from '@oneblink/apps-react'

function PaymentReceiptContainer() {
  const history = useHistory()

  const onFinished = React.useCallback(
    async (formSubmissionResult) =>
      await submissionService.executePostSubmissionAction(
        formSubmissionResult,
        history.push,
      ),
    [history.push],
  )
  const onCancel = React.useCallback(
    async (formSubmissionResult) =>
      await submissionService.executeCancelAction(
        formSubmissionResult,
        history.push,
      ),
    [history.push],
  )

  return (
    <div className="ob-payment-receipt section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            Receipt
          </h1>
        </div>
        <PaymentReceipt onDone={onFinished} onCancel={onCancel} />
      </div>
    </div>
  )
}

export default React.memo(PaymentReceiptContainer)
