import 'whatwg-fetch'
import * as React from 'react'
import { registerSW } from 'virtual:pwa-register'
import ReactDOM from 'react-dom'

import 'styles/styles.scss'
import generateCustomStyles from 'styles/custom-styles-run'
import App from './App'

generateCustomStyles()
ReactDOM.render(<App />, document.getElementById('root'))

registerSW()
