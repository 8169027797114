import { formService } from '@oneblink/apps'
import { FormsAppsTypes } from '@oneblink/types'

export const SUBMISSION_JSON_PROP = 'submission-json-v1'
export const ORIGINAL_EXTERNAL_ID_PROP = 'originalExternalId'
export type CPHCMSContentItemRow = {
  contentItem: formService.CivicPlusHCMSContentItem
  values: Array<string | undefined>
}

export type CPHCMSContentItems = {
  columns: string[]
  rows: CPHCMSContentItemRow[]
}

type Attribute = (
  | FormsAppsTypes.FormsAppCPHCMSContentMenuItemListDisplayAttributeMeta
  | FormsAppsTypes.FormsAppCPHCMSContentMenuItemListDisplayAttributeSubmissionMeta
)['attribute']

export const ATTRIBUTE_LABEL_MAP: Record<Attribute, string> = {
  'meta.created': 'Created At',
  'meta.id': 'ID',
  'meta.lastModified': 'Updated At',
  externalId: 'External ID',
}
