import * as React from 'react'
import { ScheduledTasksTypes } from '@oneblink/types'
import { MaterialIcon } from 'components'
import clsx from 'clsx'

export const ScheduledTaskResourceMobileLink = React.memo(
  function ScheduledTaskActionMobileButton({
    resource,
  }: {
    resource: ScheduledTasksTypes.LinkedResource
  }) {
    return (
      <a
        href={resource.url}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx(
          'button is-white ob-vertical-button ob-scheduled-tasks__vertical-button cypress-scheduled-task-mobile-button',
          {
            'is-loading': false,
          },
        )}
      >
        <MaterialIcon className="ob-vertical-button__icon">link</MaterialIcon>
        <span className="ob-vertical-button__text ob-scheduled-tasks__vertical-button-text">
          {resource.label}
        </span>
      </a>
    )
  },
)

function ScheduledTaskResourceLink({
  resource,
}: {
  resource: ScheduledTasksTypes.LinkedResource
}) {
  return (
    <a
      href={resource.url}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(
        'button ob-button ob-list__button cypress-scheduled-task-button ob-scheduled-tasks__button',
        {
          'is-loading': false,
          'has-margin-right-8': true,
        },
      )}
    >
      <span className="icon ob-icon">
        <MaterialIcon className="ob-scheduled-tasks__button-icon">
          link
        </MaterialIcon>
      </span>
      <span className="ob-scheduled-tasks__button-label">{resource.label}</span>
    </a>
  )
}

export default React.memo(ScheduledTaskResourceLink)
