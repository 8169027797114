import * as React from 'react'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'
import { OnLoading, MaterialIcon } from 'components'
import useScheduledTasks from 'hooks/useScheduledTasks'
import ScheduledTasksList from './ScheduledTasksList'
import { formsAppId, timezone } from 'config'
import { Grid } from '@mui/material'
import { localisationService } from '@oneblink/apps'
import { formatInTimeZone } from 'date-fns-tz'
import SubmissionDataModal from 'components/modals/SubmissionDataModal'

export type ViewTaskActionSubmissionState = {
  isShowing: boolean
  formId?: number
  submissionId?: string
}

function ScheduledTasksListContainer({
  date,
  setDate,
  taskGroupInstanceId,
}: {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  taskGroupInstanceId: string | undefined
}) {
  const {
    loadDataState: [
      scheduledTasksState,
      refreshScheduledTasks,
      setTaskResponses,
    ],
    currentDate,
    onGoToNextDay,
    onGoToPreviousDay,
  } = useScheduledTasks({
    date,
    setDate,
    formsAppId,
    taskGroupInstanceId,
  })

  const [viewTaskActionSubmissionState, setViewTaskActionSubmissionState] =
    React.useState<ViewTaskActionSubmissionState>({ isShowing: false })

  const dayOfWeek = React.useMemo(() => {
    return formatInTimeZone(currentDate, timezone, 'EEEE')
  }, [currentDate])

  const title = React.useMemo(
    () =>
      formatInTimeZone(
        currentDate,
        timezone,
        localisationService.getDateFnsFormats().longYearlessDate,
      ),
    [currentDate],
  )

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="has-margin-bottom-5"
      >
        <Grid item>
          <button
            type="button"
            className="cypress-previous-day-button button ob-button is-outlined ob-button__scheduled-tasks-change-day ob-button__scheduled-tasks-previous-day"
            onClick={onGoToPreviousDay}
          >
            <span className="icon ob-icon__scheduled-tasks-change-day">
              <MaterialIcon>chevron_left</MaterialIcon>
            </span>
            <span className="ob-text__scheduled-tasks-change-day">
              Previous Day
            </span>
          </button>
        </Grid>
        <Grid item>
          <h2 className="title is-4 scheduled-tasks-subheading">
            <span className="is-hidden-mobile">{dayOfWeek}, </span>
            {title}
          </h2>
        </Grid>
        <Grid item>
          <button
            type="button"
            className="cypress-next-day-button button ob-button is-outlined ob-button__scheduled-tasks-change-day ob-button__scheduled-tasks-next-day"
            onClick={onGoToNextDay}
          >
            <span className="ob-text__scheduled-tasks-change-day">
              Next Day
            </span>
            <span className="icon ob-icon__scheduled-tasks-change-day">
              <MaterialIcon>chevron_right</MaterialIcon>
            </span>
          </button>
        </Grid>
      </Grid>

      {scheduledTasksState.status === 'LOADING' ? (
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
          <span>Loading {title}...</span>
        </div>
      ) : scheduledTasksState.status === 'ERROR' ? (
        <ErrorMessage
          title="Error Loading Tasks"
          onTryAgain={refreshScheduledTasks}
        >
          <span className="cypress-scheduled-tasks-load-error-message">
            {scheduledTasksState.error.message}
          </span>
        </ErrorMessage>
      ) : (
        <>
          <ScheduledTasksList
            currentDate={currentDate}
            taskResponses={scheduledTasksState.result}
            setTaskResponses={setTaskResponses}
            taskGroupInstanceId={taskGroupInstanceId}
            setViewTaskActionSubmissionState={setViewTaskActionSubmissionState}
          />
          {viewTaskActionSubmissionState.isShowing &&
          viewTaskActionSubmissionState.formId &&
          viewTaskActionSubmissionState.submissionId ? (
            <SubmissionDataModal
              isOpen={
                viewTaskActionSubmissionState.isShowing &&
                viewTaskActionSubmissionState.formId !== undefined &&
                !!viewTaskActionSubmissionState.submissionId !== undefined
              }
              onClose={() =>
                setViewTaskActionSubmissionState({ isShowing: false })
              }
              submissionId={viewTaskActionSubmissionState.submissionId}
              formId={viewTaskActionSubmissionState.formId}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default React.memo(ScheduledTasksListContainer)
