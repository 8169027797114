import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import { localisationService } from '@oneblink/apps'
import { MaterialIcon } from 'components'

const end: Date | undefined = import.meta.env.VITE_ONEBLINK_APPS_DOWNTIME_END
  ? new Date(import.meta.env.VITE_ONEBLINK_APPS_DOWNTIME_END)
  : undefined
const start: Date | undefined = import.meta.env
  .VITE_ONEBLINK_APPS_DOWNTIME_START
  ? new Date(import.meta.env.VITE_ONEBLINK_APPS_DOWNTIME_START)
  : undefined

const MaintenanceMessage = ({ children }: { children: React.ReactNode }) => {
  const [now, setNow] = React.useState<Date | undefined>(undefined)

  React.useEffect(() => {
    if (!end || !start) {
      return
    }

    const run = () => {
      const newNow = new Date()
      if (newNow > end) {
        clearInterval(interval)
        setNow(undefined)
      } else {
        setNow(newNow)
      }
    }
    const interval = setInterval(run, 600000) // 10 minutes
    run()
    return () => {
      clearInterval(interval)
    }
  }, [])

  const downtimeMessage = React.useMemo(() => {
    if (start && end && now && now >= start && now <= end) {
      return `The platform is currently offline for maintenance and will be available again on ${localisationService.formatDatetimeLong(
        end,
      )}`
    }
  }, [now])

  return (
    <>
      {!downtimeMessage ? (
        children
      ) : (
        <Grid container alignItems="center" style={{ height: '100vh' }}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            alignItems="center"
            flexDirection="column"
          >
            <span className="icon">
              <MaterialIcon className="icon-x-large has-text-warning">
                construction
              </MaterialIcon>
            </span>
            <Typography variant="h6">{downtimeMessage}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MaintenanceMessage
