// @flow
'use strict'

import * as React from 'react'
import useForm from 'hooks/useForm'
import useBreadCrumbs from 'hooks/useBreadCrumbs'

const pwaSettings = window.formsHostnameConfiguration?.pwaSettings || null

function HomeScreenName() {
  const { form } = useForm()
  const breadCrumbs = useBreadCrumbs(form)

  if (!pwaSettings || !pwaSettings.homeScreenName) {
    return null
  }

  return (
    <div
      className={
        breadCrumbs.length
          ? 'title-container-is-next-to-breadcrumbs'
          : undefined
      }
    >
      <h3 className="title is-3 ob-header-navigation__app-name">
        {pwaSettings.homeScreenName}
      </h3>
    </div>
  )
}

export default React.memo(HomeScreenName)
