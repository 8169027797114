// @flow
'use strict'

import * as React from 'react'
import { draftService, jobService, submissionService } from '@oneblink/apps'
import { useIsMounted, useAuth } from '@oneblink/apps-react'
import { SubmissionTypes } from '@oneblink/types'

export type LocalFormsAppJob = SubmissionTypes.FormsAppJob & {
  draft?: draftService.LocalFormSubmissionDraft
}

export type JobsContextValue = {
  isLoading: boolean
  loadError: Error | null
  clearLoadError: () => void
  jobs: LocalFormsAppJob[]
  reloadJobs: () => unknown
  formsAppId: number
}

const defaultState = {
  isLoading: false,
  loadError: null,
  jobs: [],
}

const JobsContext = React.createContext<JobsContextValue>({
  ...defaultState,
  clearLoadError: () => {},
  reloadJobs: () => {},
  formsAppId: NaN,
})

export function JobsProvider({
  formsAppId,
  jobsLabel,
  children,
}: {
  formsAppId: number
  jobsLabel: string
  children: React.ReactNode
}) {
  const isMounted = useIsMounted()
  const { isLoggedIn, isUsingFormsKey } = useAuth()

  const [state, setState] = React.useState<{
    isLoading: boolean
    loadError: Error | null
    jobs: SubmissionTypes.FormsAppJob[]
  }>(defaultState)

  const clearLoadError = React.useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      loadError: null,
    }))
  }, [])
  const reloadJobs = React.useCallback(async () => {
    if (!isLoggedIn || isUsingFormsKey) {
      if (isMounted.current) {
        setState({
          isLoading: false,
          loadError: null,
          jobs: [],
        })
      }
      return
    }

    if (isMounted.current) {
      setState((currentState) => ({
        isLoading: true,
        loadError: null,
        jobs: currentState.jobs,
      }))
    }

    let newError = null
    let newJobs: SubmissionTypes.FormsAppJob[] = []

    try {
      newJobs = await jobService.getJobs(formsAppId, jobsLabel)
    } catch (error) {
      newError = error as Error
    }

    if (isMounted.current) {
      setState({
        isLoading: false,
        loadError: newError,
        jobs: newJobs,
      })
    }
  }, [formsAppId, isLoggedIn, isMounted, isUsingFormsKey, jobsLabel])

  const value = React.useMemo(
    () => ({
      ...state,
      formsAppId,
      reloadJobs,
      clearLoadError,
    }),
    [clearLoadError, formsAppId, reloadJobs, state],
  )

  React.useEffect(() => {
    reloadJobs()
    return submissionService.registerPendingQueueListener(reloadJobs)
  }, [reloadJobs])

  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>
}

export default function useJobs() {
  return React.useContext(JobsContext)
}
