import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '@oneblink/apps-react'

import useQuery from 'hooks/useQuery'
import Login from 'authentication/Login'
import { OnLoading, SignUpButton } from 'components'
import { authService } from '@oneblink/apps'
import { Typography } from '@mui/material'

const pwaSettings = window.formsHostnameConfiguration?.pwaSettings || null
const isGoogleLoginSupported =
  !!window.formsHostnameConfiguration?.isGoogleLoginSupported
const identityProviderName =
  window.formsHostnameConfiguration?.samlIdentityProviderName || null
const isAppUserSignUpEnabled =
  window.formsHostnameConfiguration?.isAppUserSignUpEnabled

function LoginScene() {
  const query = useQuery()
  const { isLoggedIn } = useAuth()

  React.useEffect(() => {
    if (identityProviderName && !isLoggedIn) {
      console.log(
        'Attempting login using hosted UI for identity provider',
        identityProviderName,
      )
      authService.loginHostedUI(identityProviderName)
    }
  }, [isLoggedIn])

  if (isLoggedIn) {
    return (
      <Redirect
        to={typeof query.continueTo === 'string' ? query.continueTo : '/'}
      />
    )
  }

  if (identityProviderName) {
    return (
      <section className="section ob-authentication">
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
          <span>Starting Login Process</span>
        </div>
      </section>
    )
  }

  return (
    <div className="section is-mobile-section ob-login">
      <div className="container ob-login__container">
        <div className="ob-login__box box">
          {!!pwaSettings && !!pwaSettings.homeScreenIconUrl && (
            <figure className="ob-login__figure image image is-128x128 has-margin-bottom-6">
              <img
                alt="Application Icon"
                className="is-rounded"
                src={pwaSettings.homeScreenIconUrl}
              />
            </figure>
          )}
          <Login isGoogleLoginSupported={isGoogleLoginSupported} />
        </div>
        {isAppUserSignUpEnabled && (
          <div className="container ob-login__container ob-login__box">
            <Typography variant="caption" color="GrayText" align="center">
              Need an account?
            </Typography>
            <SignUpButton className="ob-login__login-screen-button" />
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(LoginScene)
