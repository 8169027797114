import * as React from 'react'
import clsx from 'clsx'
import { OneBlinkAppsError, authService, autoSaveService } from '@oneblink/apps'
import OneBlinkAppsErrorOriginalMessage from '@oneblink/apps-react/dist/components/renderer/OneBlinkAppsErrorOriginalMessage'
import sanitizeHtml from '@oneblink/apps-react/dist/services/sanitize-html'
import {
  Modal,
  LoginButton,
  RequestAccessButton,
  MaterialIcon,
} from 'components'
import { profileMenuItem } from 'services/menu-items-service'
import { useHistory } from 'react-router-dom'
import RequestAccessSignUpButton from 'components/RequestAccessSignUpButton'
import { useBooleanState } from '@oneblink/apps-react'

type Props = {
  error: OneBlinkAppsError | Error | null
  closeButtonLabel?: string
  closeButtonClassName?: string
  onClose: () => unknown
  /** Can only ever be called if `requiresAccessRequest` is set on `error` and the app allows self sign ups. */
  onSignedUp?: () => void
}

const isAppUserSignUpEnabled =
  window.formsHostnameConfiguration?.isAppUserSignUpEnabled ?? false

function ErrorModal({
  error,
  closeButtonLabel,
  closeButtonClassName,
  onClose,
  onSignedUp,
}: Props) {
  const history = useHistory()

  const displayError = React.useMemo(() => {
    if (!error) return
    let displayError

    if (!(error instanceof OneBlinkAppsError)) {
      displayError = new OneBlinkAppsError(error.message)
    } else {
      displayError = error
    }
    return displayError
  }, [error])

  const sanitizedHtml = React.useMemo(() => {
    if (!error) return ''
    return displayError?.requiresAccessRequest && isAppUserSignUpEnabled
      ? 'You do not have access to complete this action. You can sign up below to gain the correct level of access.'
      : sanitizeHtml(error.message)
  }, [displayError?.requiresAccessRequest, error])

  const handleClose = React.useCallback(async () => {
    if (!displayError) return
    if (displayError.requiresLogin && !authService.isLoggedIn()) {
      autoSaveService.deleteAllAutosaveData()
    }

    if (displayError.requiresAccessRequest && profileMenuItem) {
      history.push(profileMenuItem.href)
      return
    }

    onClose()
  }, [displayError, history, onClose])

  const [closeButtonDisabled, disableCloseButton, enableCloseButton] =
    useBooleanState(false)

  const handleSignUpSuccess = React.useCallback(() => {
    enableCloseButton()
    onSignedUp?.()
  }, [enableCloseButton, onSignedUp])

  if (!displayError) {
    return null
  }

  const isPrimaryOkayButton =
    !displayError.requiresAccessRequest && !displayError.requiresLogin

  return (
    <Modal
      isOpen
      title={displayError.title}
      className="cypress-error-modal"
      cardClassName={clsx({
        'has-text-centered': displayError.isOffline,
      })}
      titleClassName="cypress-error-title"
      bodyClassName="cypress-error-message"
      actions={
        <>
          <button
            type="button"
            className={clsx(
              'button ob-button cypress-close-error',
              closeButtonClassName,
              {
                'is-primary': isPrimaryOkayButton,
                'is-light': !isPrimaryOkayButton,
              },
            )}
            onClick={handleClose}
            autoFocus={isPrimaryOkayButton}
            disabled={closeButtonDisabled}
          >
            {closeButtonLabel || 'Okay'}
          </button>
          {displayError.requiresAccessRequest && (
            <>
              {isAppUserSignUpEnabled ? (
                <RequestAccessSignUpButton
                  onStart={disableCloseButton}
                  onError={enableCloseButton}
                  onSuccess={handleSignUpSuccess}
                />
              ) : (
                <RequestAccessButton />
              )}
            </>
          )}
          {displayError.requiresLogin && <LoginButton autoFocus />}
        </>
      }
    >
      <>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizedHtml,
          }}
        />
        {displayError.isOffline && (
          <MaterialIcon className="has-text-warning icon-x-large">
            wifi_off
          </MaterialIcon>
        )}
        <OneBlinkAppsErrorOriginalMessage error={displayError.originalError} />
      </>
    </Modal>
  )
}

export default React.memo<Props>(ErrorModal)
