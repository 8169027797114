import * as React from 'react'
import { formService } from '@oneblink/apps'
import { Box } from '@mui/material'
import { CPHCMSContentItemRow } from './constants'
import EditContentItemButton from './EditContentItemButton'
import DeleteContentItemButton from './DeleteContentItemButton'
import PublishContentItemButton from './PublishContentItemButton'

function CPHCMSContentItemsTableRow({
  contentItem,
  values,
  selectItem,
  deleteItem,
  publishItem,
  draftItem,
}: CPHCMSContentItemRow & {
  selectItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  deleteItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  publishItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  draftItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
}) {
  return (
    <tr>
      {values.map((value, index) => (
        <td
          className="cp-hcms-content-items-list__table-cell cp-hcms-content-items-list__table-cell-width"
          key={index}
        >
          {value || '-'}
        </td>
      ))}
      <td
        align="center"
        className="cp-hcms-content-items-list__table-cell cp-hcms-content-items-list__table-cell-status-actions"
      >
        <table>
          <tr>
            <td className="cp-hcms-content-items-list__table-cell cypress-cp-hcms-content-items-status-cell">
              {contentItem.status}
            </td>
            <td
              align="center"
              className="cp-hcms-content-items-list__table-cell"
            >
              <Box display="flex">
                <EditContentItemButton
                  selectItem={selectItem}
                  contentItem={contentItem}
                />
                <DeleteContentItemButton
                  deleteItem={deleteItem}
                  contentItem={contentItem}
                />
                <PublishContentItemButton
                  contentItem={contentItem}
                  publishItem={publishItem}
                  draftItem={draftItem}
                />
              </Box>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  )
}

export default React.memo(CPHCMSContentItemsTableRow)
