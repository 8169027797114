'use strict'

import * as React from 'react'
import { authService } from '@oneblink/apps'
import { useAuth } from '@oneblink/apps-react'

const IsAuthorisedContext: React.Context<boolean | null> = React.createContext<
  boolean | null
>(null)
const SetIsAuthorisedContext: React.Context<
  (isAuthorised: boolean | null) => void
> = React.createContext<(isAuthorised: boolean | null) => void>(() => {})

export function IsAuthorisedContextProvider({
  children,
  formsAppId,
}: {
  children: React.ReactNode
  formsAppId: number
}) {
  const { isLoggedIn, isUsingFormsKey } = useAuth()

  const [isAuthorised, setIsAuthorised] = React.useState(
    isUsingFormsKey ? true : null,
  )

  React.useEffect(() => {
    if (isUsingFormsKey) {
      setIsAuthorised(true)
      return
    }

    if (!isLoggedIn) {
      setIsAuthorised(false)
      return
    }

    let ignore = false
    authService.isAuthorised(formsAppId).then((newIsAuthorised) => {
      if (!ignore) {
        setIsAuthorised(newIsAuthorised)
      }
    })
    return () => {
      ignore = true
    }
  }, [formsAppId, isUsingFormsKey, isLoggedIn])

  return (
    <SetIsAuthorisedContext.Provider value={setIsAuthorised}>
      <IsAuthorisedContext.Provider value={isAuthorised}>
        {children}
      </IsAuthorisedContext.Provider>
    </SetIsAuthorisedContext.Provider>
  )
}

/**
 * @returns `null` until the current users authorisation has been established. Then it will be `true` to indicate the user is authorised or `false` to indicate the user is not authorised.
 */
export default function useIsAuthorised() {
  return React.useContext(IsAuthorisedContext)
}

export const useSetIsAuthorised = () => {
  const setIsAuthorised = React.useContext(SetIsAuthorisedContext)
  return React.useCallback(() => {
    setIsAuthorised(true)
  }, [setIsAuthorised])
}
