import * as React from 'react'
import { useIsMounted } from '@oneblink/apps-react'
import { ScheduledTasksTypes } from '@oneblink/types'
import clsx from 'clsx'
import { ErrorModal, MaterialIcon } from 'components'
import { HandleAction } from 'hooks/useScheduledTaskActions'
import ScheduledTaskListItemContent from './ScheduledTaskListItemContent'
import {
  LeadingActions,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from 'react-swipeable-list'

function SwipeActionContent({
  className,
  action,
}: {
  className: string
  action: ScheduledTasksTypes.TaskAction
}) {
  return (
    <div
      className={clsx(
        'is-flex is-flex-direction-row p-4	has-margin-bottom-8 ob-scheduled-tasks__swipe-action',
        className,
      )}
    >
      <MaterialIcon className="is-flex is-align-items-center mr-1 ob-scheduled-tasks__swipe-actions-icon">
        {action.icon}
      </MaterialIcon>
      <span className="ob-scheduled-tasks__swipe-action-label is-flex is-align-items-center">
        {action.label}
      </span>
    </div>
  )
}

function LeadingActionsContainer({
  leadingAction,
  onAction,
}: {
  leadingAction: ScheduledTasksTypes.TaskAction
  onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
}) {
  return (
    <LeadingActions>
      <SwipeAction destructive={true} onClick={() => onAction(leadingAction)}>
        <SwipeActionContent
          className="has-background-success-light has-text-success ob-scheduled-tasks__swipe-action-leading"
          action={leadingAction}
        />
      </SwipeAction>
    </LeadingActions>
  )
}

function TrailingActionsContainer({
  trailingAction,
  onAction,
}: {
  trailingAction: ScheduledTasksTypes.TaskAction
  onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
}) {
  return (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={() => onAction(trailingAction)}>
        <SwipeActionContent
          className="has-background-info-light has-text-info ob-scheduled-tasks__swipe-action-trailing"
          action={trailingAction}
        />
      </SwipeAction>
    </TrailingActions>
  )
}

function ScheduledTaskSwipeableListItem({
  isSelected,
  actions,
  scheduledTask,
  onAction,
  onSelect,
  daysAvailable,
}: {
  isSelected: boolean
  actions: ScheduledTasksTypes.TaskAction[]
  scheduledTask: ScheduledTasksTypes.Task
  onAction: HandleAction
  onSelect: (taskId: string) => void
  daysAvailable: number
}) {
  const isMounted = useIsMounted()
  const [{ isRunningAction, error }, setState] = React.useState<{
    isRunningAction: boolean
    error: Error | null
  }>({
    isRunningAction: false,
    error: null,
  })
  const clearError = React.useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      error: null,
    }))
  }, [])

  const handleAction = React.useCallback(
    async (action: ScheduledTasksTypes.TaskAction) => {
      setState({
        isRunningAction: true,
        error: null,
      })
      try {
        await onAction(action, scheduledTask)
        if (isMounted.current) {
          setState({
            isRunningAction: false,
            error: null,
          })
        }
      } catch (error) {
        if (isMounted.current) {
          setState({
            isRunningAction: false,
            error: error as Error,
          })
        }
      }
    },
    [onAction, scheduledTask, isMounted],
  )

  const leadingAction = React.useMemo(() => {
    const leadingActionId = scheduledTask.swipeRightActionId
    if (leadingActionId) {
      return actions.find((a) => a.taskActionId === leadingActionId)
    }
  }, [actions, scheduledTask])

  const trailingAction = React.useMemo(() => {
    const trailingActionId = scheduledTask.swipeLeftActionId
    if (trailingActionId) {
      return actions.find((a) => a.taskActionId === trailingActionId)
    }
  }, [actions, scheduledTask])

  const SWIPE_THRESHOLD = 0.3
  const MAX_SWIPE = 0.75
  const [currentSwipeProgress, setCurrentSwipeProgress] = React.useState(0)

  return (
    <>
      <SwipeableListItem
        leadingActions={
          leadingAction && (
            <LeadingActionsContainer
              leadingAction={leadingAction}
              onAction={handleAction}
            />
          )
        }
        trailingActions={
          trailingAction && (
            <TrailingActionsContainer
              trailingAction={trailingAction}
              onAction={handleAction}
            />
          )
        }
        threshold={SWIPE_THRESHOLD}
        maxSwipe={MAX_SWIPE}
        onSwipeProgress={(progress) => {
          if ('vibrate' in navigator) {
            // "progress" is provided as a number between 0-100
            const progressDecimal = progress / 100

            // vibrate the users device when they cross over the swipe threshold for triggering an action
            if (
              (currentSwipeProgress >= SWIPE_THRESHOLD &&
                progressDecimal < SWIPE_THRESHOLD) ||
              (currentSwipeProgress < SWIPE_THRESHOLD &&
                progressDecimal >= SWIPE_THRESHOLD)
            ) {
              navigator.vibrate(50)
            }
            setCurrentSwipeProgress(progressDecimal)
          }
        }}
        onSwipeEnd={() => setCurrentSwipeProgress(0)}
      >
        <ScheduledTaskListItemContent
          isSelected={isSelected}
          actions={actions}
          scheduledTask={scheduledTask}
          onAction={handleAction}
          onSelect={onSelect}
          daysAvailable={daysAvailable}
          isRunningAction={isRunningAction}
          borderColor={daysAvailable > 0 ? 'warning' : 'primary'}
        />
      </SwipeableListItem>

      <ErrorModal error={error} onClose={clearError} />
    </>
  )
}

export default React.memo(ScheduledTaskSwipeableListItem)
