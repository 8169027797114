import { OneBlinkAppsError, formService } from '@oneblink/apps'
import { FormTypes } from '@oneblink/types'

const formsAppId = window.formsHostnameConfiguration?.formsAppId || NaN
const organisationId =
  window.formsHostnameConfiguration?.organisationId || 'UNKNOWN'

async function getForms(abortSignal: AbortSignal): Promise<FormTypes.Form[]> {
  return formService.getForms(formsAppId, abortSignal)
}

async function getForm({
  formId,
  formSlug,
  abortSignal,
}: {
  formId: number | null
  formSlug: string | null
  abortSignal: AbortSignal
}): Promise<FormTypes.Form> {
  const form = await formService.getForm({
    formId: formId || undefined,
    formSlug: formSlug || undefined,
    formsAppId,
    abortSignal,
  })
  if (form.organisationId === organisationId) {
    return form
  }
  console.warn(
    'Form does not belong to the organisation associated with the current forms app',
    {
      formsHostnameConfiguration: window.formsHostnameConfiguration,
      form,
    },
  )
  throw new OneBlinkAppsError(
    'We could not find the form you are looking for. Please contact your administrator to ensure your form configuration has been completed successfully.',
    {
      title: 'Unknown Form',
    },
  )
}

export default {
  getForms,
  getForm,
}
