import * as React from 'react'
import { formService } from '@oneblink/apps'
import { MaterialIcon } from 'components'
import { Tooltip } from '@mui/material'

function PublishContentItemButton({
  contentItem,
  publishItem,
  draftItem,
}: {
  contentItem: formService.CivicPlusHCMSContentItem
  publishItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  draftItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
}) {
  if (contentItem.status === 'Published') {
    return (
      <Tooltip title="Draft Content" arrow>
        <button
          className="button ob-button ob-list__button is-inverted is-black cypress-cp-hcms-content-item-draft-button"
          onClick={() => draftItem(contentItem)}
        >
          <span className="icon is-small">
            <MaterialIcon
              className="ob-cp-hcms-content-items__icon ob-icon__draft-content"
              aria-hidden={false}
            >
              public_off
            </MaterialIcon>
          </span>
        </button>
      </Tooltip>
    )
  }

  if (contentItem.status === 'Draft') {
    return (
      <Tooltip title="Publish Content" arrow>
        <button
          className="button ob-button ob-list__button is-inverted is-black cypress-cp-hcms-content-item-publish-button"
          onClick={() => publishItem(contentItem)}
        >
          <span className="icon is-small">
            <MaterialIcon
              className="ob-cp-hcms-content-items__icon ob-icon__publish-content"
              aria-hidden={false}
            >
              public
            </MaterialIcon>
          </span>
        </button>
      </Tooltip>
    )
  }

  return null
}

export default React.memo(PublishContentItemButton)
