import * as React from 'react'
import { useHistory } from 'react-router-dom'
import {
  schedulingService,
  submissionService,
  localisationService,
  OneBlinkAppsError,
} from '@oneblink/apps'
import { useIsMounted } from '@oneblink/apps-react'

import useQuery from 'hooks/useQuery'
import { OnLoading, Modal, MaterialIcon } from 'components'
import {
  Receipt,
  ReceiptList,
  ReceiptListItem,
  ReceiptButton,
} from '@oneblink/apps-react/dist/components/receipts'

function SchedulingReceipt() {
  const isMounted = useIsMounted()
  const query = useQuery()
  const history = useHistory()

  const [
    { isLoading, loadError, booking, formSubmissionResult },
    setLoadState,
  ] = React.useState<{
    isLoading: boolean
    loadError: Error | null
    booking: schedulingService.SchedulingBooking | null
    formSubmissionResult: submissionService.FormSubmissionResult | null
  }>({
    isLoading: true,
    loadError: null,
    booking: null,
    formSubmissionResult: null,
  })

  const [
    { isRunningPostSubmissionAction, postSubmissionError },
    setPostSubmissionState,
  ] = React.useState<{
    isRunningPostSubmissionAction: boolean
    postSubmissionError: OneBlinkAppsError | null
  }>({
    isRunningPostSubmissionAction: false,
    postSubmissionError: null,
  })
  const clearPostSubmissionError = React.useCallback(() => {
    setPostSubmissionState((currentState) => ({
      ...currentState,
      postSubmissionError: null,
    }))
  }, [])

  const executePostSubmissionAction = React.useCallback(async () => {
    if (!isMounted.current || !formSubmissionResult) {
      return
    }

    setPostSubmissionState({
      isRunningPostSubmissionAction: true,
      postSubmissionError: null,
    })

    let newError = null
    try {
      await submissionService.executePostSubmissionAction(
        {
          ...formSubmissionResult,
          scheduling: null,
        },
        history.push,
      )
    } catch (error) {
      console.warn('Error while running post submission action', error)
      newError = error as OneBlinkAppsError
    }

    if (isMounted.current) {
      setPostSubmissionState({
        isRunningPostSubmissionAction: false,
        postSubmissionError: newError,
      })
    }
  }, [formSubmissionResult, history.push, isMounted])

  React.useEffect(() => {
    let ignore = false

    const getBooking = async () => {
      let newError = null
      let newBooking = null
      let newFormSubmissionResult = null
      try {
        const result =
          await schedulingService.handleSchedulingQuerystring(query)
        if (result.formSubmissionResult?.payment) {
          return await submissionService.executePostSubmissionAction(
            {
              ...result.formSubmissionResult,
              scheduling: null,
            },
            history.push,
          )
        }
        newBooking = result.booking
        newFormSubmissionResult = result.formSubmissionResult || null
      } catch (error) {
        console.warn('Error while attempting to load booking', error)
        newError = error as Error
      }

      if (!ignore) {
        setLoadState({
          isLoading: false,
          loadError: newError,
          booking: newBooking,
          formSubmissionResult: newFormSubmissionResult,
        })
      }
    }
    getBooking()

    return () => {
      ignore = true
    }
  }, [history.push, query])

  return (
    <div className="ob-scheduling-receipt section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            Booking
          </h1>
        </div>

        {isLoading && (
          <section>
            <div className="cypress-loading has-text-centered">
              <OnLoading className="has-text-centered"></OnLoading>
              <span>Retrieving booking details...</span>
            </div>
          </section>
        )}

        {booking && (
          <Receipt
            className="ob-scheduling-receipt"
            containerClassName="ob-scheduling-receipt__container"
          >
            <ReceiptList successIconClassName="ob-scheduling-receipt__success-icon">
              <ReceiptListItem
                className="ob-scheduling-receipt__submission-id"
                valueClassName="cypress-scheduling-receipt-submission-id"
                icon="receipt"
                label="Submission Id"
                value={booking.submissionId}
                allowCopyToClipboard
              />

              <ReceiptListItem
                className="ob-scheduling-receipt__location"
                valueClassName="cypress-scheduling-receipt-location"
                icon="location_on"
                label="Location"
                value={booking.location}
              />

              <ReceiptListItem
                className="ob-scheduling-receipt__start-time"
                valueClassName="cypress-scheduling-receipt-start-time"
                icon="schedule"
                label="Start Time"
                value={localisationService.formatDatetimeLong(
                  booking.startTime,
                )}
              />

              <ReceiptListItem
                className="ob-scheduling-receipt__end-time"
                valueClassName="cypress-scheduling-receipt-end-time"
                icon="schedule"
                label="End Time"
                value={localisationService.formatDatetimeLong(booking.endTime)}
              />
            </ReceiptList>

            {formSubmissionResult && (
              <div className="buttons">
                <ReceiptButton
                  className="is-primary ob-scheduling-receipt__button ob-scheduling-receipt__okay-button cypress-scheduling-receipt-okay-button"
                  label="Done"
                  isLoading={isRunningPostSubmissionAction}
                  onClick={executePostSubmissionAction}
                />
              </div>
            )}
          </Receipt>
        )}

        {loadError && (
          <section className="cypress-scheduling-receipt-loading-error-message">
            <div className="ob-scheduling-receipt__error-icon-container has-text-centered has-margin-bottom-8">
              <MaterialIcon className="ob-scheduling-receipt__error-icon has-text-danger icon-x-large">
                error
              </MaterialIcon>
            </div>
            <p className="ob-scheduling-receipt__error-message has-text-centered has-margin-bottom-4">
              {loadError.message}
            </p>
          </section>
        )}
      </div>

      {postSubmissionError && (
        <Modal
          isOpen
          title={postSubmissionError.title || 'Whoops...'}
          bodyClassName="cypress-scheduling-receipt-retry-error-message"
          actions={
            <button
              type="button"
              className="button ob-button is-primary cypress-scheduling-receipt-retry-error-okay-button"
              onClick={clearPostSubmissionError}
              autoFocus
            >
              Okay
            </button>
          }
        >
          {postSubmissionError.message}
        </Modal>
      )}
    </div>
  )
}

export default React.memo(SchedulingReceipt)
