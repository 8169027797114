import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const CordovaAndroidBackHandler = React.memo(
  function CordovaAndroidBackHandler() {
    const location = useLocation()
    const history = useHistory()

    React.useEffect(() => {
      // Need this hack because of this issue:
      // https://github.com/ionic-team/ionic/issues/17984
      const listener = () => {
        if (location.pathname === '/') {
          window.cordova.plugins.exit()
        } else {
          history.goBack()
        }
      }
      document.addEventListener('backbutton', listener)
      return () => {
        document.removeEventListener('backbutton', listener)
      }
    }, [history, location.pathname])

    return null
  },
)

function AndroidBackHandler() {
  if (window.cordova) {
    return <CordovaAndroidBackHandler />
  }
  return null
}

export default React.memo(AndroidBackHandler)
