// @flow
'use strict'

import * as React from 'react'
import { FormTypes } from '@oneblink/types'

type Form = FormTypes.Form

type FormContextValue = {
  form: Form | null
  setForm: (newForm: Form | null) => void
}

const defaultState = {
  form: null,
  setForm: () => {},
}

const FormContext: React.Context<FormContextValue> = React.createContext<FormContextValue>(
  defaultState,
)

export function FormProvider({ children }: { children: React.ReactNode }) {
  const [form, setForm] = React.useState<Form | null>(defaultState.form)

  const value = {
    form,
    setForm,
  }

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export default function useForm() {
  return React.useContext(FormContext)
}
