import * as React from 'react'
import { ScheduledTasksTypes } from '@oneblink/types'
import ScheduledTaskActionButton, {
  ScheduledTaskActionMobileButton,
} from './ScheduledTaskActionButton'
import ScheduledTaskListItemContentBase from './ScheduledTaskListItemContentBase'
import ScheduledTaskResourceLink, {
  ScheduledTaskResourceMobileLink,
} from './ScheduledTaskResourceLink'

function ScheduledTaskListItemContent({
  isSelected,
  isRunningAction,
  actions,
  scheduledTask,
  daysAvailable,
  onAction,
  onSelect,
  isDisabled,
  borderColor,
}: {
  isSelected: boolean
  isRunningAction: boolean
  actions: ScheduledTasksTypes.TaskAction[]
  scheduledTask: ScheduledTasksTypes.Task
  daysAvailable: number
  onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
  onSelect: (taskId: string) => void
  isDisabled?: boolean
  borderColor: React.ComponentProps<
    typeof ScheduledTaskListItemContentBase
  >['borderColor']
}) {
  return (
    <ScheduledTaskListItemContentBase
      scheduledTask={scheduledTask}
      daysAvailable={daysAvailable}
      isSelected={isSelected}
      onSelect={onSelect}
      borderColor={borderColor}
      trailing={
        <>
          {scheduledTask.linkedResources?.map((resource, index) => {
            return (
              <React.Fragment key={index}>
                <ScheduledTaskResourceLink resource={resource} />
              </React.Fragment>
            )
          })}
          {actions.map((action, index) => {
            return (
              <React.Fragment key={action.label}>
                <ScheduledTaskActionButton
                  action={action}
                  isDisabled={isRunningAction || !!isDisabled}
                  onAction={onAction}
                  isFirst={index === 0}
                />
              </React.Fragment>
            )
          })}
        </>
      }
      appendix={
        <>
          {scheduledTask.linkedResources?.map((resource, index) => {
            return (
              <React.Fragment key={index}>
                <ScheduledTaskResourceMobileLink resource={resource} />
              </React.Fragment>
            )
          })}
          {actions.map((action) => {
            return (
              <React.Fragment key={action.label}>
                <ScheduledTaskActionMobileButton
                  action={action}
                  isDisabled={isRunningAction || !!isDisabled}
                  onAction={onAction}
                />
              </React.Fragment>
            )
          })}
        </>
      }
    />
  )
}

export default React.memo(ScheduledTaskListItemContent)
