import { draftService } from '@oneblink/apps'

export default function generateResumeDraftUrl({
  draft,
  parentTile,
}: {
  draft: draftService.LocalFormSubmissionDraft
  parentTile?: string
}): string | undefined {
  const url = new URL(`/forms/${draft.formId}`, window.location.origin)
  const latestVersion = draftService.getLatestFormSubmissionDraftVersion(
    draft.versions,
  )
  const draftId =
    draft.draftSubmission?.formSubmissionDraftId ||
    latestVersion?.formSubmissionDraftId
  if (!draftId) {
    return
  }

  url.searchParams.append('draftId', draftId)
  if (draft.externalId) {
    url.searchParams.append('externalId', draft.externalId)
  }
  if (draft.jobId) {
    url.searchParams.append('jobId', draft.jobId)
  }
  if (draft.previousFormSubmissionApprovalId) {
    url.searchParams.append(
      'previousFormSubmissionApprovalId',
      draft.previousFormSubmissionApprovalId,
    )
  }
  if (draft.taskId) {
    url.searchParams.append('taskId', draft.taskId)
  }
  if (draft.taskActionId) {
    url.searchParams.append('taskActionId', draft.taskActionId)
  }
  if (draft.taskGroupInstanceId) {
    url.searchParams.append('taskGroupInstanceId', draft.taskGroupInstanceId)
  }
  if (parentTile) {
    url.searchParams.append('parentTile', parentTile)
  }
  return `${url.pathname}${url.search}`
}
