import { useIsMounted } from '@oneblink/apps-react'
import { ScheduledTasksTypes } from '@oneblink/types'
import clsx from 'clsx'
import * as React from 'react'
import { MaterialIcon } from 'components'

function useHandleAction({
  action,
  onAction,
}: {
  action: ScheduledTasksTypes.TaskAction
  onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
}) {
  const isMounted = useIsMounted()
  const [isRunning, setIsRunning] = React.useState(false)
  const handleAction = React.useCallback(async () => {
    setIsRunning(true)
    await onAction(action)
    if (isMounted.current) {
      setIsRunning(false)
    }
  }, [action, isMounted, onAction])
  return {
    isRunning,
    handleAction,
  }
}

export const ScheduledTaskActionMobileButton = React.memo(
  function ScheduledTaskActionMobileButton({
    action,
    isDisabled,
    onAction,
  }: {
    action: ScheduledTasksTypes.TaskAction
    isDisabled: boolean
    onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
  }) {
    const { isRunning, handleAction } = useHandleAction({
      action,
      onAction,
    })

    return (
      <button
        className={clsx(
          'button is-white ob-vertical-button ob-scheduled-tasks__vertical-button cypress-scheduled-task-mobile-button',
          {
            'is-loading': isRunning,
          },
        )}
        disabled={isDisabled}
        onClick={handleAction}
      >
        <MaterialIcon className="ob-vertical-button__icon">
          {action.icon}
        </MaterialIcon>
        <span className="ob-vertical-button__text ob-scheduled-tasks__vertical-button-text">
          {action.label}
        </span>
      </button>
    )
  },
)

function ScheduledTaskActionButton({
  action,
  isDisabled,
  isFirst,
  onAction,
}: {
  action: ScheduledTasksTypes.TaskAction
  isDisabled: boolean
  isFirst: boolean
  onAction: (action: ScheduledTasksTypes.TaskAction) => Promise<void>
}) {
  const { isRunning, handleAction } = useHandleAction({
    action,
    onAction,
  })

  return (
    <button
      className={clsx(
        'button ob-button ob-list__button cypress-scheduled-task-button ob-scheduled-tasks__button',
        {
          'is-loading': isRunning,
          'has-margin-left-8': !isFirst,
        },
      )}
      onClick={handleAction}
      disabled={isDisabled}
    >
      <span className="icon ob-icon">
        <MaterialIcon className="ob-scheduled-tasks__button-icon">
          {action.icon}
        </MaterialIcon>
      </span>
      <span className="ob-scheduled-tasks__button-label">{action.label}</span>
    </button>
  )
}

export default React.memo(ScheduledTaskActionButton)
