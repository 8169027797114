import * as React from 'react'
import { formService } from '@oneblink/apps'
import CPHCMSContentItemsTableRow from './CPHCMSContentItemsTableRow'
import { CPHCMSContentItems } from './constants'

function CPHCMSContentItemsTable({
  selectItem,
  deleteItem,
  publishItem,
  draftItem,
  contentItems,
}: {
  selectItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  deleteItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  publishItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  draftItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  contentItems: CPHCMSContentItems
}) {
  return (
    <table className="table is-raised-1 is-hoverable is-fullwidth cp-hcms-content-items-list__table">
      <thead>
        <tr>
          {contentItems.columns.map((column, index) => (
            <th key={index} className="cp-hcms-content-items-list__table-cell">
              {column}
            </th>
          ))}
          <th
            align="center"
            className="cp-hcms-content-items-list__table-cell cp-hcms-content-items-list__table-cell-status-actions"
          >
            <table>
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>Actions</td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </thead>
      <tbody>
        {contentItems.rows.map((row) => (
          <CPHCMSContentItemsTableRow
            key={row.contentItem.id}
            selectItem={selectItem}
            deleteItem={deleteItem}
            publishItem={publishItem}
            draftItem={draftItem}
            {...row}
          />
        ))}
      </tbody>
    </table>
  )
}

export default React.memo(CPHCMSContentItemsTable)
