import * as React from 'react'
import { Chip } from '@mui/material'
import { localisationService } from '@oneblink/apps'
import { ScheduledTasksTypes } from '@oneblink/types'
import { MaterialIcon } from 'components'

function CompletedAtChip({
  completedTask,
  taskAction,
}: {
  completedTask: ScheduledTasksTypes.CompletedTask
  taskAction: ScheduledTasksTypes.TaskAction | undefined
}) {
  return (
    <Chip
      className="cypress-scheduled-task-chip-completed-at ob-completed-task__chip ob-completed-task__status-chip"
      variant="outlined"
      color={taskAction ? undefined : 'warning'}
      icon={
        <MaterialIcon>{taskAction ? 'schedule' : 'alarm_off'}</MaterialIcon>
      }
      label={
        taskAction
          ? localisationService.formatTime(new Date(completedTask.createdAt))
          : 'Expired'
      }
      size="medium"
    />
  )
}

export default React.memo(CompletedAtChip)
