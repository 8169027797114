import * as React from 'react'
import { Collapse } from '@mui/material'
import { ScheduledTasksTypes } from '@oneblink/types'
import clsx from 'clsx'
import useIntervalClass from '../useIntervalClass'

function ScheduledTaskListItemContent({
  isSelected,
  onSelect,
  scheduledTask,
  daysAvailable,
  trailing,
  trailingMobile,
  appendix,
  borderColor,
}: {
  isSelected?: boolean
  onSelect?: (taskId: string) => void
  scheduledTask: ScheduledTasksTypes.Task
  daysAvailable: number
  trailing: React.ReactNode
  trailingMobile?: React.ReactNode
  appendix?: React.ReactNode
  borderColor: 'warning' | 'primary'
}) {
  const intervalClass = useIntervalClass(scheduledTask)

  const overdue = daysAvailable > 0

  return (
    <div
      className={`ob-scheduled-tasks__list-item-content has-margin-bottom-8 is-raised-1 has-thick-border-left is-${borderColor}-thick-border-left`}
    >
      <div
        className={clsx(
          'ob-list__item ob-scheduled-tasks__list-item cypress-scheduled-task-list-item',
          `ob-scheduled-tasks__${scheduledTask.schedule.recurrence.interval.toLowerCase()}`,
          intervalClass,
          {
            'ob-scheduled-tasks__list-item-overdue': overdue,
            'is-showing-mobile-actions': isSelected,
            'is-clickable-mobile': onSelect,
          },
        )}
        onClick={
          !onSelect
            ? undefined
            : (event) => {
                const computedStyle = getComputedStyle(event.currentTarget)
                const cursorStyle = computedStyle.getPropertyValue('cursor')
                if (cursorStyle === 'pointer') {
                  onSelect(scheduledTask.taskId)
                }
              }
        }
      >
        <div className="ob-list__content-wrapper ob-scheduled-tasks__list-item-content-wrapper">
          <div className="ob-list__content">
            <div className="ob-list__text-primary">{scheduledTask.name}</div>
            {overdue && (
              <div className="ob-list__text-secondary">
                Due {daysAvailable} day{daysAvailable > 1 ? 's' : ''} ago
              </div>
            )}
          </div>
        </div>
        <div className="ob-list__actions ob-scheduled-tasks__list-item-actions is-hidden-mobile">
          {trailing}
        </div>
        <div className="ob-list__actions ob-scheduled-tasks__list-item-actions is-hidden-tablet">
          {trailingMobile}
        </div>
      </div>

      <Collapse in={isSelected}>
        <div className="ob-scheduled-tasks__mobile-actions is-hidden-tablet">
          {appendix}
        </div>
      </Collapse>
    </div>
  )
}

export default React.memo(ScheduledTaskListItemContent)
