import { Button, Snackbar } from '@mui/material'
import {
  attachmentsService,
  localisationService,
  submissionService,
} from '@oneblink/apps'
import { useNullableState } from '@oneblink/apps-react'
import { getFormSubmission } from '@oneblink/apps/dist/services/pending-queue'
import clsx from 'clsx'
import { OnLoading, MaterialIcon } from 'components'
import * as React from 'react'
import AttachmentProgressBar from './AttachmentProgressBar'
import PendingQueueProgressBar from './PendingQueueProgressBar'

interface Props {
  pendingSubmission: submissionService.PendingFormSubmission
  onDelete: (timestamp: string) => void
  onEdit: (timestamp: string) => void
}

const PendingSubmission = ({ pendingSubmission, onDelete, onEdit }: Props) => {
  const [submission, setSubmission] =
    useNullableState<submissionService.FormSubmission>(null)
  const [getSubmissionError, setGetSubmissionError, clearGetSubmissionError] =
    useNullableState<Error>(null)

  React.useEffect(() => {
    const getSubmission = async () => {
      try {
        const sub = await getFormSubmission(pendingSubmission.pendingTimestamp)
        setSubmission(sub)
      } catch (err) {
        setGetSubmissionError(err as Error)
      }
    }
    getSubmission()
  }, [pendingSubmission.pendingTimestamp, setGetSubmissionError, setSubmission])

  const attachments = React.useMemo(() => {
    if (!submission) return
    return attachmentsService.getSubmissionAttachmentDetails(
      pendingSubmission.definition.elements,
      submission.submission,
    )
  }, [pendingSubmission.definition.elements, submission])

  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    return submissionService.registerPendingQueueProgressListener(
      pendingSubmission.pendingTimestamp,
      (event) => {
        setProgress(Math.round(event.progress))
      },
    )
  }, [pendingSubmission.pendingTimestamp])

  return (
    <div className="ob-list__item">
      <div
        className={clsx('ob-list__avatar', {
          'tooltip has-tooltip-right':
            pendingSubmission.definition.isAuthenticated ||
            pendingSubmission.isEditing,
        })}
        data-tooltip={
          pendingSubmission.isEditing
            ? 'An edit was initiated on this submission. It will not be processed until you click the Edit button and submit or cancel the form.'
            : pendingSubmission.definition.isAuthenticated
              ? 'Login required to process submission.'
              : undefined
        }
      >
        <PendingSubmissionIcon pendingSubmission={pendingSubmission} />
      </div>
      <div className="ob-list__content">
        <div className="ob-list__text-primary ob-list__form-name">
          {pendingSubmission.definition.name}
        </div>
        {pendingSubmission.taskCompletion && (
          <div className="ob-list__text-primary ob-list__task-name">
            {pendingSubmission.taskCompletion.task.name}
          </div>
        )}
        <div className="ob-list__text-secondary ob-list__pending-timestamp">
          {localisationService.formatDatetime(
            new Date(pendingSubmission.pendingTimestamp),
          )}
        </div>
        {!!attachments?.length && (
          <div className="ob-progress__pending-queue-attachments-container">
            {attachments.map((attachment, i) => (
              <AttachmentProgressBar attachment={attachment} key={i} />
            ))}
          </div>
        )}

        <PendingQueueProgressBar
          progress={progress}
          title="Submission"
          isEditing={pendingSubmission.isEditing}
        />

        {!!pendingSubmission.error && (
          <div className="ob-list__text-tertiary has-text-danger">
            {pendingSubmission.error}
          </div>
        )}
      </div>

      <div className="ob-list__actions">
        <button
          type="button"
          disabled={pendingSubmission.isSubmitting}
          className="button ob-button ob-list__button is-inverted is-black tooltip has-tooltip-left"
          onClick={() => onDelete(pendingSubmission.pendingTimestamp)}
          data-tooltip="Delete submission"
        >
          <span className="icon is-small">
            <MaterialIcon
              className="ob-pending-queue__icon ob-icon__delete-submission"
              aria-hidden={false}
            >
              delete
            </MaterialIcon>
          </span>
        </button>
        <button
          type="button"
          disabled={pendingSubmission.isSubmitting}
          className="button ob-button ob-list__button is-inverted is-black tooltip has-tooltip-left"
          onClick={() => onEdit(pendingSubmission.pendingTimestamp)}
          data-tooltip="Edit submission"
        >
          <span className="icon is-small">
            <MaterialIcon
              className="ob-pending-queue__icon ob-icon__edit-submission"
              aria-hidden={false}
            >
              edit
            </MaterialIcon>
          </span>
        </button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!getSubmissionError}
        autoHideDuration={50000}
        message={`Failed to load attachment progress for a submission: ${getSubmissionError?.message}`}
        onClose={clearGetSubmissionError}
        action={
          <Button color="secondary" onClick={clearGetSubmissionError}>
            Ok
          </Button>
        }
      />
    </div>
  )
}

function PendingSubmissionIcon({
  pendingSubmission,
}: {
  pendingSubmission: submissionService.PendingFormSubmission
}) {
  if (pendingSubmission.isSubmitting) {
    return <OnLoading />
  }
  if (pendingSubmission.isEditing) {
    return <MaterialIcon className="ob-list__icon">pause_circle</MaterialIcon>
  }

  if (pendingSubmission.error) {
    return (
      <MaterialIcon className="ob-list__icon has-text-danger">
        warning
      </MaterialIcon>
    )
  }

  return (
    <MaterialIcon className="ob-list__icon">
      {pendingSubmission.definition.isAuthenticated ? 'lock' : 'label'}
    </MaterialIcon>
  )
}

export default React.memo<Props>(PendingSubmission)
