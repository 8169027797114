import * as React from 'react'
import { authService } from '@oneblink/apps'
import { useBooleanState, useNullableState } from '@oneblink/apps-react'

import { Modal, ErrorModal, OnLoading } from 'components'

const formsAppId = window.formsHostnameConfiguration?.formsAppId || NaN

function RequestAccessButton({ autoFocus = false }: { autoFocus?: boolean }) {
  const [isRequestingAccess, setIsRequestingAccess] = React.useState(false)
  const [hasRequestedAccess, accessRequestedOn, accessRequestedOff] =
    useBooleanState(false)
  const [error, setError, clearError] = useNullableState(null)

  const handleRequestAccess = React.useCallback(() => {
    setIsRequestingAccess(true)
    return authService
      .requestAccess(formsAppId)
      .then(() => {
        accessRequestedOn()
      })
      .catch((error) => {
        setError(error)
      })
      .then(() => {
        setIsRequestingAccess(false)
      })
  }, [accessRequestedOn, setError])

  return (
    <>
      <button
        type="button"
        className="button ob-button is-primary ob-button__request-access cypress-request-access-button"
        disabled={isRequestingAccess}
        onClick={handleRequestAccess}
        autoFocus={autoFocus}
      >
        Request Access
      </button>

      <ErrorModal error={error} onClose={clearError} />

      <Modal
        isOpen={isRequestingAccess || hasRequestedAccess}
        title={isRequestingAccess ? 'Requesting Access' : 'Access Requested'}
        actions={
          <button
            className="button ob-button is-primary"
            disabled={isRequestingAccess}
            onClick={accessRequestedOff}
            autoFocus
          >
            Okay
          </button>
        }
      >
        {isRequestingAccess && (
          <div className="cypress-requesting-access">
            <OnLoading className="has-text-centered" small />
          </div>
        )}
        {hasRequestedAccess && (
          <p className="cypress-access-requested-message ob-text__access-requested">
            Your request has been sent. Please wait to be contacted by your
            administrator before trying again.
          </p>
        )}
      </Modal>
    </>
  )
}

export default React.memo(RequestAccessButton)
