// @flow
'use strict'

import * as React from 'react'
import { notificationService } from '@oneblink/apps'
import { useIsMounted } from '@oneblink/apps-react'

export type NotificationsContextValue = {
  isSubscribed: boolean
  subscribe: () => Promise<void>
  unsubscribe: () => Promise<void>
}

const defaultValue = {
  isSubscribed: false,
  subscribe: async () => {},
  unsubscribe: async () => {},
}

const NotificationsContext = React.createContext<NotificationsContextValue>(
  defaultValue,
)

export function NotificationsProvider({
  formsAppId,
  children,
}: {
  formsAppId: number
  children: React.ReactNode
}) {
  const isMounted = useIsMounted()

  const [isSubscribed, setIsSubscribed] = React.useState(false)

  const subscribe = React.useCallback(async () => {
    const newIsSubscribed = await notificationService.subscribe(formsAppId)
    if (isMounted.current) {
      setIsSubscribed(newIsSubscribed)
    }
  }, [formsAppId, isMounted])

  const unsubscribe = React.useCallback(async () => {
    await notificationService.unsubscribe(formsAppId)
    if (isMounted.current) {
      setIsSubscribed(false)
    }
  }, [formsAppId, isMounted])

  const value = React.useMemo(
    () => ({
      subscribe,
      unsubscribe,
      isSubscribed,
    }),
    [isSubscribed, unsubscribe, subscribe],
  )

  React.useEffect(() => {
    let ignore = false
    const init = async () => {
      try {
        const newIsSubscribed = await notificationService.isSubscribed()
        if (!ignore) {
          setIsSubscribed(newIsSubscribed)
        }
      } catch (error) {
        console.error('Notifications Service init error', error)
      }
    }
    init()

    return () => {
      ignore = true
    }
  }, [])

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}

export default function useNotifications() {
  return React.useContext(NotificationsContext)
}
