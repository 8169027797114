import * as React from 'react'
import { ScheduledTasksTypes } from '@oneblink/types'
import ScheduledTaskListItemContentBase from './ScheduledTaskListItemContentBase'
import ActionChip from './ActionChip'
import CompletedByChip from './CompletedByChip'
import CompletedAtChip from './CompletedAtChip'
import { ViewTaskActionSubmissionState } from 'scheduled-tasks/ScheduledTasksListContainer'

function CompletedScheduledTaskListItemContent({
  actions,
  scheduledTask,
  completedTask,
  daysAvailable,
  isSelected,
  onSelect,
  setViewTaskActionSubmissionState,
}: {
  actions: ScheduledTasksTypes.TaskAction[]
  scheduledTask: ScheduledTasksTypes.Task
  completedTask: ScheduledTasksTypes.CompletedTask
  daysAvailable: number
  isSelected: boolean
  onSelect: (taskId: string) => void
  setViewTaskActionSubmissionState: React.Dispatch<
    React.SetStateAction<ViewTaskActionSubmissionState>
  >
}) {
  const usedAction = React.useMemo(() => {
    return actions.find(
      (a) => a.versionId === completedTask.taskActionVersionId,
    )
  }, [actions, completedTask.taskActionVersionId])

  return (
    <ScheduledTaskListItemContentBase
      scheduledTask={scheduledTask}
      daysAvailable={daysAvailable}
      isSelected={isSelected}
      onSelect={usedAction ? onSelect : undefined}
      borderColor={usedAction ? 'primary' : 'warning'}
      trailingMobile={
        usedAction ? (
          <ActionChip
            taskAction={usedAction}
            completedTask={completedTask}
            setViewTaskActionSubmissionState={setViewTaskActionSubmissionState}
          />
        ) : (
          <CompletedAtChip
            taskAction={usedAction}
            completedTask={completedTask}
          />
        )
      }
      trailing={
        <>
          <CompletedByChip
            className="has-margin-right-8"
            completedTask={completedTask}
          />
          <CompletedAtChip
            taskAction={usedAction}
            completedTask={completedTask}
          />
          <ActionChip
            taskAction={usedAction}
            completedTask={completedTask}
            setViewTaskActionSubmissionState={setViewTaskActionSubmissionState}
          />
        </>
      }
      appendix={
        <div className="ob-completed-task__mobile-details-wrapper">
          <CompletedByChip
            className="has-margin-right-8"
            completedTask={completedTask}
          />
          <CompletedAtChip
            taskAction={usedAction}
            completedTask={completedTask}
          />
        </div>
      }
    />
  )
}

export default React.memo(CompletedScheduledTaskListItemContent)
