import { submissionService } from '@oneblink/apps'
import { useLoadDataState } from '@oneblink/apps-react'
import * as React from 'react'

export default function useFormSubmissionData({
  formId,
  submissionId,
}: {
  formId: number
  submissionId: string
}) {
  const loadSubmissionData = React.useCallback(
    async (abortSignal: AbortSignal) => {
      return await submissionService.getSubmissionData({
        formId,
        submissionId,
        abortSignal,
      })
    },
    [formId, submissionId],
  )
  return useLoadDataState(loadSubmissionData)
}
