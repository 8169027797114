import * as React from 'react'
import clsx from 'clsx'
import { Chip, useTheme } from '@mui/material'
import { ScheduledTasksTypes } from '@oneblink/types'
import { ViewTaskActionSubmissionState } from 'scheduled-tasks/ScheduledTasksListContainer'
import { MaterialIcon } from 'components'

function ActionChip({
  className,
  taskAction,
  completedTask,
  setViewTaskActionSubmissionState,
}: {
  className?: string
  taskAction: ScheduledTasksTypes.TaskAction | undefined
  completedTask: ScheduledTasksTypes.CompletedTask
  setViewTaskActionSubmissionState: React.Dispatch<
    React.SetStateAction<ViewTaskActionSubmissionState>
  >
}) {
  const theme = useTheme()
  const viewSubmission = React.useCallback(() => {
    if (
      taskAction &&
      taskAction.type === 'FORM' &&
      taskAction.formId &&
      completedTask.submissionId
    ) {
      setViewTaskActionSubmissionState({
        isShowing: true,
        formId: taskAction.formId,
        submissionId: completedTask.submissionId,
      })
    }
  }, [completedTask.submissionId, setViewTaskActionSubmissionState, taskAction])

  const hasSubmission = React.useMemo(
    () => !!completedTask.submissionId,
    [completedTask.submissionId],
  )

  if (!taskAction) {
    return null
  }

  return (
    <Chip
      icon={<MaterialIcon>{taskAction?.icon}</MaterialIcon>}
      variant="outlined"
      label={
        <div className="ob-completed-task__view-submission-label">
          {taskAction.label}
          {hasSubmission && (
            <MaterialIcon
              className=".ob-completed-task__view-submission-chip-icon has-margin-left-8"
              style={{ color: `${theme.palette.primary.main}` }}
            >
              open_in_new
            </MaterialIcon>
          )}
        </div>
      }
      size="medium"
      color="primary"
      className={clsx(
        'cypress-scheduled-task-chip-action ob-completed-task__chip ob-completed-task__action-chip has-tooltip-left has-tooltip-arrow',
        className,
      )}
      clickable={hasSubmission}
      onClick={(e) => {
        e.stopPropagation()
        viewSubmission()
      }}
      data-tooltip={hasSubmission ? 'View Submission' : undefined}
      sx={{ marginLeft: '0.5rem' }}
    />
  )
}

export default React.memo(ActionChip)
