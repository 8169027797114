import * as React from 'react'
import { formService } from '@oneblink/apps'
import { MaterialIcon } from 'components'
import { Tooltip } from '@mui/material'

function DeleteContentItemButton({
  contentItem,
  deleteItem,
}: {
  contentItem: formService.CivicPlusHCMSContentItem
  deleteItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
}) {
  return (
    <Tooltip title="Delete Content" arrow>
      <button
        className="button ob-button ob-list__button is-inverted is-black cypress-cp-hcms-content-item-delete-button"
        onClick={() => deleteItem(contentItem)}
      >
        <span className="icon is-small">
          <MaterialIcon
            className="ob-cp-hcms-content-items__icon ob-icon__edit-submission"
            aria-hidden={false}
          >
            delete
          </MaterialIcon>
        </span>
      </button>
    </Tooltip>
  )
}

export default React.memo(DeleteContentItemButton)
