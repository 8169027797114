import React from 'react'
import { ScheduledTasksTypes } from '@oneblink/types'

export default function useIntervalClass(
  scheduledTask: ScheduledTasksTypes.Task,
) {
  return React.useMemo(() => {
    switch (scheduledTask.schedule.recurrence.interval) {
      case 'WEEK': {
        return `ob-scheduled-tasks__${scheduledTask.schedule.recurrence.day.toLowerCase()}`
      }
      case 'CUSTOM': {
        return `ob-scheduled-tasks__days-${scheduledTask.schedule.recurrence.days}`
      }
      case 'DAY':
      default: {
        return
      }
    }
  }, [scheduledTask.schedule.recurrence])
}
