import { formService } from '@oneblink/apps'
import * as React from 'react'
import EditContentItemButton from './EditContentItemButton'
import DeleteContentItemButton from './DeleteContentItemButton'
import { Grid, Box } from '@mui/material'
import { CPHCMSContentItems } from './constants'
import PublishContentItemButton from './PublishContentItemButton'

function CPHCMSContentItemsGrid({
  selectItem,
  deleteItem,
  publishItem,
  draftItem,
  contentItems,
}: {
  selectItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  deleteItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  publishItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  draftItem: (contentItem: formService.CivicPlusHCMSContentItem) => void
  contentItems: CPHCMSContentItems
}) {
  return (
    <div className="ob-list cypress-cp-hcms-content-items-list">
      {contentItems.rows.map(({ contentItem, values }) => {
        return (
          <React.Fragment key={contentItem.id}>
            <div className="ob-list__item hcms-content-list__item has-shadow cypress-cp-hcms-content-item">
              <div className="ob-list__content-wrapper">
                <div className="ob-list__content hcms-content">
                  <Grid container>
                    {values.map((value, index) => {
                      const label = contentItems.columns[index]
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <HCMSAttributeContent label={label} value={value} />
                        </Grid>
                      )
                    })}
                    <Grid item xs={12} sm={6}>
                      <HCMSAttributeContent
                        label="Status"
                        value={contentItem.status}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Box
                className="ob-list__actions"
                display="flex"
                flexDirection="column"
              >
                <EditContentItemButton
                  contentItem={contentItem}
                  selectItem={selectItem}
                />
                <DeleteContentItemButton
                  contentItem={contentItem}
                  deleteItem={deleteItem}
                />
                <PublishContentItemButton
                  contentItem={contentItem}
                  publishItem={publishItem}
                  draftItem={draftItem}
                />
              </Box>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const MAX_CONTENT_LENGTH = 200

function HCMSAttributeContent({
  label,
  value,
}: {
  label: string | undefined
  value: string | undefined
}) {
  const trimmedValue = React.useMemo(() => {
    if (!value) {
      return '-'
    }
    if (value.length <= MAX_CONTENT_LENGTH) {
      return value
    }
    return value.substring(0, MAX_CONTENT_LENGTH) + '...'
  }, [value])

  if (!label) {
    return null
  }

  return (
    <div className="hcms-content-item">
      <p className="hcms-content-item__label">
        <span>{label}</span>
      </p>
      {trimmedValue && (
        <p className="hcms-content-item__value cypress-cp-hcms-content-item-value">
          <span>{trimmedValue}</span>
        </p>
      )}
    </div>
  )
}

export default React.memo(CPHCMSContentItemsGrid)
