import * as React from 'react'
import clsx from 'clsx'
import TextareaElement from '@oneblink/apps-react/dist/form-elements/FormElementTextarea'
import { FormTypes } from '@oneblink/types'
const cancellationReasonElement: FormTypes.TextareaElement = {
  id: 'reason-for-cancellation',
  name: 'reason-for-cancellation',
  label: 'Reason for Cancellation *',
  conditionallyShow: false,
  isDataLookup: false,
  isElementLookup: false,
  readOnly: false,
  required: false,
  requiresAllConditionallyShowPredicates: false,
  type: 'textarea',
  conditionallyShowPredicates: [],
}
const emptyFn = () => {}
interface Props {
  onSubmit: (reason: string) => Promise<void>
}

const CancelForm = ({ onSubmit }: Props) => {
  const [reason, setReason] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()
      setIsLoading(true)
      await onSubmit(reason)
      setIsLoading(false)
    },
    [onSubmit, reason],
  )
  return (
    <form onSubmit={handleSubmit}>
      <TextareaElement
        id={cancellationReasonElement.id}
        element={cancellationReasonElement}
        value={reason}
        onChange={(e, { value }) => {
          if (typeof value === 'string') {
            setReason(value)
          } else if (typeof value === 'function') {
            const newValue = value(reason)
            setReason(newValue || '')
          } else {
            setReason('')
          }
        }}
        displayValidationMessage={false}
        validationMessage={undefined}
        isDirty={false}
        setIsDirty={emptyFn}
      />
      <div className="buttons has-margin-top-6">
        <button
          type="submit"
          className={clsx(
            'button ob-button ob-receipt__button is-primary ob-scheduling-cancel__submit-button cypress-scheduling-cancel-submit-button',
            {
              'is-loading': isLoading,
            },
          )}
          disabled={isLoading || !reason}
        >
          Cancel Booking
        </button>
      </div>
    </form>
  )
}

export default React.memo<Props>(CancelForm)
