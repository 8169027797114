import * as React from 'react'
import { scheduledTasksService } from '@oneblink/apps'
import { useLoadDataState } from '@oneblink/apps-react'
import { formatInTimeZone } from 'date-fns-tz'
import { add, sub } from 'date-fns'
import {
  scheduledTasksMenuItem,
  scheduledTaskGroupsMenuItem,
} from 'services/menu-items-service'
import { timezone } from 'config'

export function getDateString(date: Date) {
  return formatInTimeZone(date, timezone, 'yyyy-MM-dd')
}

export interface TaskResponse extends scheduledTasksService.TaskResponse {
  isAnimatingTaskCompletion: boolean
  isAnimatingTaskUndo: boolean
}

export default function useScheduledTasks({
  date: dateState,
  setDate: setDateState,
  formsAppId,
  taskGroupInstanceId,
}: {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  formsAppId: number
  taskGroupInstanceId: string | undefined
}) {
  const onGoToPreviousDay = React.useCallback(() => {
    setDateState((currentDateState) => sub(currentDateState, { days: 1 }))
  }, [setDateState])

  const onGoToNextDay = React.useCallback(() => {
    setDateState((currentDateState) => add(currentDateState, { days: 1 }))
  }, [setDateState])

  const loadScheduledTasks = React.useCallback(
    async (abortSignal: AbortSignal): Promise<TaskResponse[]> => {
      if (
        (!scheduledTasksMenuItem && !taskGroupInstanceId) ||
        (taskGroupInstanceId && !scheduledTaskGroupsMenuItem)
      ) {
        return []
      }
      const date = getDateString(dateState)
      const { taskResponses } =
        taskGroupInstanceId !== undefined
          ? await scheduledTasksService.getTaskGroupInstanceTasks({
              taskGroupInstanceId,
              date,
              formsAppId,
              abortSignal,
            })
          : await scheduledTasksService.getTasksForFormsApp({
              formsAppId,
              date,
              abortSignal,
            })
      return taskResponses.map((taskResponses) => ({
        ...taskResponses,
        isAnimatingTaskCompletion: false,
        isAnimatingTaskUndo: false,
      }))
    },
    [taskGroupInstanceId, dateState, formsAppId],
  )
  return {
    loadDataState: useLoadDataState(loadScheduledTasks),
    currentDate: dateState,
    onGoToPreviousDay,
    onGoToNextDay,
  }
}
