import * as React from 'react'
import clsx from 'clsx'
import { Modal, ErrorModal } from 'components'

function CPHCMSDeleteContentModal({
  handleCancelDelete,
  isConfirming,
  isDeleting,
  handleDelete,
  error,
  clearDeleteError,
}: {
  handleCancelDelete: () => void
  handleDelete: () => void
  isConfirming: boolean
  isDeleting: boolean
  error: React.ComponentProps<typeof ErrorModal>['error']
  clearDeleteError: () => void
}) {
  return (
    <>
      <Modal
        isOpen={isConfirming}
        title="Delete Content"
        titleClassName="ob-title__deleting"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-light ob-button__cancel"
              disabled={isDeleting}
              onClick={handleCancelDelete}
            >
              Cancel
            </button>
            <button
              type="button"
              className={clsx(
                'button ob-button is-primary ob-button__delete cypress-confirm-content-delete',
                { 'is-loading': isDeleting },
              )}
              disabled={isDeleting}
              onClick={handleDelete}
              autoFocus
            >
              Delete
            </button>
          </>
        }
      >
        <p>Do you wish to delete this content?</p>
      </Modal>

      <ErrorModal error={error} onClose={clearDeleteError} />
    </>
  )
}

export default React.memo(CPHCMSDeleteContentModal)
