import * as React from 'react'

import { MaterialIcon, Modal } from 'components'

type Props = {
  isOpen: boolean
  onClose: (isOpen: false) => void
}

function OfflineLoginModal({ isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      title="It looks like you're offline"
      cardClassName="has-text-centered"
      actions={
        <button
          type="button"
          className="button ob-button is-primary"
          onClick={() => onClose(false)}
          autoFocus
        >
          Okay
        </button>
      }
    >
      <MaterialIcon className="has-text-warning icon-x-large">
        wifi_off
      </MaterialIcon>
      <p>
        You cannot login while offline, please try again when connectivity is
        restored.
      </p>
    </Modal>
  )
}

export default React.memo<Props>(OfflineLoginModal)
