import * as React from 'react'
import clsx from 'clsx'
import { Modal, ErrorModal } from 'components'

function CPHCMSPublishContentModal({
  handleCancelPublish,
  isConfirming,
  isPublishing,
  handlePublish,
  error,
  clearPublishError,
}: {
  handleCancelPublish: () => void
  handlePublish: () => void
  isConfirming: boolean
  isPublishing: boolean
  error: React.ComponentProps<typeof ErrorModal>['error']
  clearPublishError: () => void
}) {
  return (
    <>
      <Modal
        isOpen={isConfirming}
        title="Publish Content"
        titleClassName="ob-title__publishing"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-light ob-button__cancel"
              disabled={isPublishing}
              onClick={handleCancelPublish}
            >
              Cancel
            </button>
            <button
              type="button"
              className={clsx(
                'button ob-button is-primary ob-button__delete cypress-confirm-content-publish',
                { 'is-loading': isPublishing },
              )}
              disabled={isPublishing}
              onClick={handlePublish}
              autoFocus
            >
              Publish
            </button>
          </>
        }
      >
        <p>Do you wish to publish this content?</p>
      </Modal>

      <ErrorModal error={error} onClose={clearPublishError} />
    </>
  )
}

export default React.memo(CPHCMSPublishContentModal)
