import React from 'react'
import { PaymentForm } from '@oneblink/apps-react'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'

export default function PaymentFormScene() {
  const onCompleted = React.useCallback(
    ({ paymentReceiptUrl }: { paymentReceiptUrl: string }) => {
      window.location.replace(paymentReceiptUrl)
    },
    [],
  )

  if (!window.formsHostnameConfiguration?.recaptchaPublicKey) {
    return (
      <ErrorMessage title="Invalid App Configuration" gutterTop>
        <span className="ob-payment-form__no-recaptcha">
          Payments cannot be completed in the application due to missing
          configuration. Please contact your administrator to rectify the
          missing reCAPTCHA configuration issue.
        </span>
      </ErrorMessage>
    )
  }

  return (
    <div className="ob-payment-form section is-mobile-section">
      <div className="container">
        <div className="ob-payment-form__wrapper">
          <PaymentForm
            captchaSiteKey={
              window.formsHostnameConfiguration.recaptchaPublicKey
            }
            captchaType={window.formsHostnameConfiguration.recaptchaKeyType}
            onCompleted={onCompleted}
            onCancelled={onCompleted}
            title={
              window.formsHostnameConfiguration.pwaSettings?.homeScreenName
            }
            appImageUrl={
              window.formsHostnameConfiguration.pwaSettings?.homeScreenIconUrl
            }
          />
        </div>
      </div>
    </div>
  )
}
