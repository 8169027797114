import * as React from 'react'
import { submissionService, attachmentsService } from '@oneblink/apps'
import PendingQueueProgressBar from './PendingQueueProgressBar'

const AttachmentProgressBar = ({
  attachment,
}: {
  attachment: attachmentsService.SubmissionAttachmentDetail
}) => {
  const [progress, setProgress] = React.useState(
    attachment.needsToUpload ? 0 : 100,
  )

  React.useEffect(() => {
    if (progress !== 100 && attachment.needsToUpload) {
      return submissionService.registerPendingQueueAttachmentProgressListener(
        attachment.value._id,
        (event) => {
          setProgress(Math.round(event.progress))
        },
      )
    }
  }, [attachment.needsToUpload, attachment.value, progress])

  return (
    <PendingQueueProgressBar
      title={attachment.value.fileName}
      progress={progress}
    />
  )
}

export default React.memo(AttachmentProgressBar)
