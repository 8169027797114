import clsx from 'clsx'
import React from 'react'
import { MaterialIcon } from 'components'

const Input = React.memo(function Input({
  name,
  type,
  label,
  value,
  onChange,
  isInvalid,
  icon,
  autoComplete,
  ...rest
}: {
  name: string
  type: React.ComponentProps<'input'>['type']
  label: string
  value: string
  onChange: (newValue: string) => void
  isInvalid: boolean | null
  icon?: string
  autoComplete?: string
}) {
  const isShowingRightIcon = isInvalid !== null
  return (
    <div className="field" {...rest}>
      <div
        className={clsx('control', {
          'has-icons-left': icon,
          'has-icons-right': isShowingRightIcon,
        })}
      >
        <input
          name={name}
          className="ob-input input"
          required
          type={type}
          placeholder={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          autoComplete={autoComplete}
        />
        {icon && (
          <span className="ob-input-icon icon is-small is-left">
            <MaterialIcon className="is-size-5">{icon}</MaterialIcon>
          </span>
        )}
        {isShowingRightIcon && (
          <span className="ob-input-icon icon is-small is-right">
            <MaterialIcon
              className={clsx('is-size-5', {
                'has-text-success': !isInvalid,
              })}
            >
              {isInvalid ? 'warning' : 'check'}
            </MaterialIcon>
          </span>
        )}
      </div>
    </div>
  )
})

export default Input
