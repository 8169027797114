import * as React from 'react'
import sanitizeHtml from '@oneblink/apps-react/dist/services/sanitize-html'
import { Modal } from 'components'
import { submissionService } from '@oneblink/apps'

function FormUnpublishedModal({
  unpublishedUserMessageHtml,
}: {
  unpublishedUserMessageHtml: string
}) {
  const sanitizedHtml = React.useMemo(() => {
    return sanitizeHtml(
      unpublishedUserMessageHtml || 'This form is currently unpublished.',
    )
  }, [unpublishedUserMessageHtml])

  return (
    <Modal
      isOpen
      title={unpublishedUserMessageHtml ? undefined : 'Form Unavailable'}
      className="cypress-form-unpublished-modal"
      actions={
        <button
          className="button ob-button is-primary"
          onClick={() => submissionService.goBackOrCloseWindow()}
          autoFocus
        >
          Okay
        </button>
      }
    >
      <div
        className="cypress-unpublished-user-message-content ob-form-unpublished-user-message__content ql-editor"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitizedHtml,
        }}
      />
    </Modal>
  )
}

export default React.memo(FormUnpublishedModal)
