import * as React from 'react'
import { toast, Flip } from 'react-toastify'
import { Alert, styled, Box, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { scheduledTasksService } from '@oneblink/apps'
import { ScheduledTasksTypes } from '@oneblink/types'
import { useBooleanState } from '@oneblink/apps-react'
import { MaterialIcon } from 'components'

const StyledAlert = styled(Alert)({
  color: 'white',
  alignItems: 'center',
  backgroundColor: 'rgb(50, 50, 50)',
  '& .MuiAlert-action': {
    paddingTop: 0,
  },
  '& .MuiIconButton-root': {
    color: `rgba(255, 255, 255, 0.54)`,
  },
})

export type HandleUndoCompleteTask = (taskId: string) => void

function ScheduledTaskUndoAlert({
  completedTask,
  scheduledTask,
  onUndo,
  onClose,
}: {
  completedTask: ScheduledTasksTypes.CompletedTask
  scheduledTask: ScheduledTasksTypes.Task
  onUndo: HandleUndoCompleteTask
  onClose: (() => void) | undefined
}) {
  const openErrorToast = React.useCallback(() => {
    toast(
      ({ closeToast }) => (
        <StyledAlert
          severity="error"
          variant="filled"
          className="cypress-scheduled-task-undo-completed-task-error"
          elevation={3}
          action={
            <IconButton color="inherit" onClick={closeToast}>
              <MaterialIcon>close</MaterialIcon>
            </IconButton>
          }
        >
          Failed to undo completed task: {scheduledTask.name}
        </StyledAlert>
      ),
      {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: true,
        pauseOnHover: true,
        closeOnClick: false,
        closeButton: false,
        transition: Flip,
      },
    )
  }, [scheduledTask.name])

  const [isUndoing, startUndoing, stopUndoing] = useBooleanState(false)

  const handleUndo = React.useCallback(async () => {
    try {
      startUndoing()
      await scheduledTasksService.deleteCompletedTask(completedTask.id)
      onUndo(scheduledTask.taskId)
      onClose?.()
    } catch (e) {
      console.error(
        `There was an error undoing completed task: ${completedTask.id}`,
        e,
      )
      openErrorToast()
    }
    stopUndoing()
  }, [
    completedTask.id,
    onClose,
    onUndo,
    openErrorToast,
    scheduledTask.taskId,
    startUndoing,
    stopUndoing,
  ])

  return (
    <StyledAlert
      variant="filled"
      elevation={3}
      className={`cypress-scheduled-task-undo-completed-task-${completedTask.id}`}
      action={
        <Box display="flex">
          <LoadingButton
            onClick={handleUndo}
            loading={isUndoing}
            className="ob-scheduled-tasks__undo-button ob-snack-button"
          >
            <span>Undo</span>
          </LoadingButton>
          <IconButton
            onClick={onClose}
            size="small"
            className="ob-scheduled-tasks__undo-close-button"
            disabled={isUndoing}
          >
            <MaterialIcon aria-hidden={false}>close</MaterialIcon>
          </IconButton>
        </Box>
      }
    >
      Completed {scheduledTask.name}
    </StyledAlert>
  )
}

export default React.memo(ScheduledTaskUndoAlert)
