import * as React from 'react'
import { usePendingSubmissions } from '@oneblink/apps-react'
import ErrorSnackbar from '@oneblink/apps-react/dist/components/ErrorSnackbar'
import { Alert, Snackbar } from '@mui/material'

export default function PendingQueueNotification() {
  const {
    pendingSubmissions,
    isShowingSuccessNotification,
    isShowingFailedNotification,
    hideFailedNotification,
    hideSuccessNotification,
  } = usePendingSubmissions()

  return (
    <>
      <ErrorSnackbar
        open={isShowingFailedNotification}
        onClose={hideFailedNotification}
      >
        A submission in the pending queue failed to process
      </ErrorSnackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isShowingSuccessNotification}
      >
        <Alert
          onClose={hideSuccessNotification}
          severity={pendingSubmissions.length ? 'info' : 'success'}
          elevation={6}
          variant="filled"
        >
          {pendingSubmissions.length
            ? `A submission was processed by the pending queue (${pendingSubmissions.length} remaining)`
            : 'All submissions in the pending queue have been processed'}
        </Alert>
      </Snackbar>
    </>
  )
}
