import * as React from 'react'
import { MaterialIcon } from 'components'

interface Props {
  message: string
}

const CancelCompleted = ({ message }: Props) => {
  return (
    <div className="ob-scheduling-cancel__cancel-completed-container">
      <MaterialIcon
        className={
          'has-text-centered has-text-success icon-x-large ob-scheduling-cancel__cancel-completed-icon'
        }
      >
        check_circle_outline
      </MaterialIcon>
      <p className="has-text-centered has-margin-bottom-2">{message}</p>
    </div>
  )
}

export default React.memo<Props>(CancelCompleted)
