import * as React from 'react'
import { authService } from '@oneblink/apps'

import { OnLoading } from 'components'
import useQuery from 'hooks/useQuery'

export const LOGOUT_CONTINUE_TO = 'LOGOUT_CONTINUE_TO'

function SignOutScene() {
  const query = useQuery()

  React.useEffect(() => {
    if (typeof query.continueTo === 'string') {
      localStorage.setItem(LOGOUT_CONTINUE_TO, query.continueTo)
    }
    authService.logoutHostedUI()
  }, [query.continueTo])

  return (
    <section className="section ob-authentication">
      <div className="cypress-loading has-text-centered">
        <OnLoading className="has-text-centered" />
        <span>Signing Out</span>
      </div>
    </section>
  )
}

export default React.memo(SignOutScene)
