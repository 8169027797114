import { prefillService, autoSaveService } from '@oneblink/apps'
import utilsService from '@oneblink/apps/dist/services/utils'

// Add these services to the window to allow cypress to use
// them for test fixture setup that required data stored locally
window.cypress_utilsService = utilsService
window.cypress_prefillService = prefillService
window.cypress_autoSaveService = autoSaveService

declare global {
  interface Window {
    cypress_utilsService: typeof utilsService
    cypress_prefillService: typeof prefillService
    cypress_autoSaveService: typeof autoSaveService
  }
}
