import * as React from 'react'
import {
  schedulingService,
  localisationService,
  OneBlinkAppsError,
} from '@oneblink/apps'
import { useIsMounted, useBooleanState } from '@oneblink/apps-react'

import useQuery from 'hooks/useQuery'
import { Modal, MaterialIcon } from 'components'
import { ReceiptListItem } from '@oneblink/apps-react/dist/components/receipts'
import CancelForm from './CancelForm'
import CancelCompleted from './CancelCompleted'

function SchedulingCancel() {
  const isMounted = useIsMounted()
  const query = useQuery()

  const [hasBeenCancelled, setHasBeenCancelled] = useBooleanState(false)

  const [{ isLoading, parseError, cancelError, booking }, setState] =
    React.useState<{
      isLoading: boolean
      parseError: Error | null
      cancelError: OneBlinkAppsError | null
      booking: ReturnType<
        typeof schedulingService.handleCancelSchedulingBookingQuerystring
      > | null
    }>({
      isLoading: true,
      parseError: null,
      cancelError: null,
      booking: null,
    })

  // Parse booking data in query string
  React.useEffect(() => {
    let parseError = null
    let booking = null
    try {
      booking =
        schedulingService.handleCancelSchedulingBookingQuerystring(query)
    } catch (err) {
      parseError = err as Error
      console.warn('Error while attempting to load booking', err)
    }
    setState({
      isLoading: false,
      parseError,
      cancelError: null,
      booking,
    })
  }, [query])

  const handleCancel = React.useCallback(
    async (reason: string) => {
      if (!booking) return
      let cancelError = null
      setState({
        isLoading: true,
        parseError: null,
        cancelError,
        booking,
      })
      try {
        await schedulingService.cancelSchedulingBooking({
          submissionId: booking.submissionId,
          nylasEditHash: booking.nylasEditHash,
          reason,
        })
        setHasBeenCancelled()
      } catch (err) {
        cancelError = err as OneBlinkAppsError
        console.warn('Error while attempting to cancel booking', err)
      }
      if (isMounted.current) {
        setState({
          isLoading: false,
          parseError: null,
          cancelError,
          booking,
        })
      }
    },
    [booking, isMounted, setHasBeenCancelled],
  )

  const clearCancelError = React.useCallback(() => {
    setState({
      isLoading,
      parseError,
      cancelError: null,
      booking,
    })
  }, [booking, isLoading, parseError])

  return (
    <div className="ob-scheduling-cancel section is-mobile-section">
      <div className="container">
        {booking && (
          <>
            <div className="ob-scheduling-cancel__header-container has-margin-bottom-5">
              <MaterialIcon
                className={
                  'has-text-centered has-text-warning icon-x-large ob-scheduling-cancel__cancel-icon'
                }
              >
                event_busy
              </MaterialIcon>
              <p className="ob-scheduling-cancel__heading has-text-centered">
                Are you sure you want to cancel the booking?
              </p>
            </div>
            <div className="ob-scheduling-cancel__card">
              <div className="columns">
                <div className="column is-half ob-scheduling-cancel__booking-details-container">
                  <div className="ob-list">
                    <ReceiptListItem
                      className="ob-scheduling-cancel__event-name"
                      valueClassName="cypress-scheduling-cancel-event-name"
                      icon="event_note"
                      label="Event"
                      value={booking.eventName}
                    />
                    <ReceiptListItem
                      className="ob-scheduling-cancel__location"
                      valueClassName="cypress-scheduling-cancel-location"
                      icon="location_on"
                      label="Location"
                      value={booking.location}
                    />
                    <ReceiptListItem
                      className="ob-scheduling-cancel__start-time"
                      valueClassName="cypress-scheduling-cancel-start-time"
                      icon="schedule"
                      label="Start Time"
                      value={localisationService.formatDatetimeLong(
                        booking.startTime,
                      )}
                    />

                    <ReceiptListItem
                      className="ob-scheduling-cancel__end-time"
                      valueClassName="cypress-scheduling-cancel-end-time"
                      icon="schedule"
                      label="End Time"
                      value={localisationService.formatDatetimeLong(
                        booking.endTime,
                      )}
                    />
                  </div>
                </div>
                <div className="column is-half">
                  {hasBeenCancelled ? (
                    <CancelCompleted message="The booking has been successfully cancelled." />
                  ) : (
                    <CancelForm onSubmit={handleCancel} />
                  )}
                </div>
              </div>
              {!!booking.cancellationPolicy && (
                <div className="ob-scheduling-cancel__cancellation-policy-container">
                  <p className="has-text-centered ob-scheduling-cancel__cancellation-policy">
                    {booking.cancellationPolicy}
                  </p>
                </div>
              )}
            </div>
          </>
        )}

        {parseError && (
          <section className="cypress-scheduling-cancel-parse-error-message">
            <div className="ob-scheduling-cancel__error-icon-container has-text-centered has-margin-bottom-8">
              <MaterialIcon className="ob-scheduling-cancel__error-icon has-text-danger icon-x-large">
                error
              </MaterialIcon>
            </div>
            <p className="ob-scheduling-cancel__error-message has-text-centered has-margin-bottom-4">
              {parseError.message}
            </p>
          </section>
        )}
      </div>
      {cancelError && (
        <Modal
          isOpen
          title={cancelError.title || 'Whoops...'}
          bodyClassName="cypress-scheduling-cancel-retry-error-message"
          actions={
            <button
              type="button"
              className="button ob-button is-primary cypress-scheduling-cancel-retry-error-okay-button"
              onClick={clearCancelError}
              autoFocus
            >
              Okay
            </button>
          }
        >
          {cancelError.message}
        </Modal>
      )}
    </div>
  )
}

export default React.memo(SchedulingCancel)
