import * as React from 'react'
import { scheduledTaskGroupsLabel } from 'services/menu-items-service'
import RequiresAuthorisationSection from 'components/RequiresAuthorisationSection'
import ScheduledTaskGroupsInstancesList from './ScheduledTaskGroupInstancesList'

function ScheduledTaskGroupInstancesScene() {
  return (
    <div className="ob-scheduled-task-group-instances section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            {scheduledTaskGroupsLabel}
          </h1>
        </div>
        <RequiresAuthorisationSection label={scheduledTaskGroupsLabel}>
          <ScheduledTaskGroupsInstancesList />
        </RequiresAuthorisationSection>
      </div>
    </div>
  )
}

export default React.memo(ScheduledTaskGroupInstancesScene)
