import * as React from 'react'
import { useParams } from 'react-router-dom'
import { scheduledTaskGroupInstances } from 'services/menu-items-service'
import ScheduledTasksScene from 'scheduled-tasks/ScheduledTasksScene'

function ScheduledTaskGroupInstanceScene() {
  const params = useParams<{ taskGroupInstanceId: string }>()

  const taskGroupInstanceLabel = React.useMemo(() => {
    return (
      scheduledTaskGroupInstances?.find(
        (instance) =>
          instance.taskGroupInstanceId === params.taskGroupInstanceId,
      )?.label || 'Scheduled Tasks'
    )
  }, [params.taskGroupInstanceId])

  return (
    <ScheduledTasksScene
      title={taskGroupInstanceLabel}
      taskGroupInstanceId={params.taskGroupInstanceId}
    />
  )
}

export default React.memo(ScheduledTaskGroupInstanceScene)
