import * as React from 'react'

function RouteNotFound() {
  return (
    <div className="modal is-active cypress-not-found-modal">
      <div className="modal-background-faded"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title cypress-error-title">Whoops...</p>
        </header>
        <section className="modal-card-body">
          The page you are looking for does not exist, please contact your
          administrator if you believe it should.
        </section>
      </div>
    </div>
  )
}

export default React.memo(RouteNotFound)
