import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { formService } from '@oneblink/apps'
import { useIsOffline, useIsMounted } from '@oneblink/apps-react'

import formsApiService from 'services/forms-api-service'
import { menuItems } from 'services/menu-items-service'
import useQuery from 'hooks/useQuery'
import { FormTypes } from '@oneblink/types'
import useFilteredDrafts from './useFilteredDrafts'

export default function useFetchForms() {
  const isMounted = useIsMounted()
  const history = useHistory()
  const { tile: tileFilter } = useQuery()

  const isOffline = useIsOffline()

  const {
    filteredDrafts,
    isSyncingAndReloadingDrafts,
    actions: { filterDrafts, unfilterDrafts },
  } = useFilteredDrafts()

  const [{ isLoading, loadError, forms }, setState] = React.useState<{
    isLoading: boolean
    loadError: Error | null
    forms: FormTypes.Form[]
  }>({
    isLoading: true,
    loadError: null,
    forms: [],
  })

  const clearLoadError = React.useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      loadError: null,
    }))
  }, [])

  const reloadFormsList = React.useCallback(
    async (abortSignal: AbortSignal) => {
      if (isMounted.current) {
        setState((currentState) => ({
          isLoading: true,
          loadError: null,
          forms: currentState.forms,
        }))
      }

      let newError = null
      let newForms: FormTypes.Form[] = []

      try {
        const allForms = await formsApiService.getForms(abortSignal)
        newForms = allForms.filter((form) => {
          const startDate = form.publishStartDate
            ? new Date(form.publishStartDate)
            : null
          const endDate = form.publishEndDate
            ? new Date(form.publishEndDate)
            : null
          const now = new Date()
          // Only return forms that are currently published
          return !((startDate && now < startDate) || (endDate && now > endDate))
        })

        console.log(`Found ${newForms.length} form(s)`)

        const tile = menuItems.find((menuItem) => menuItem.label === tileFilter)
        if (tile && tile.type === 'CONTAINER') {
          newForms = tile.formIds.reduce<FormTypes.Form[]>((memo, formId) => {
            const form = newForms.find((form) => form.id === formId)
            if (form) {
              memo.push(form)
            }
            return memo
          }, [])
          console.log(
            `Reduced to ${newForms.length} form(s) based on tile filter`,
            tileFilter,
          )
        }

        formService
          .loadFormElementDynamicOptions(newForms, abortSignal)
          .catch(console.warn)
      } catch (error) {
        newError = error as Error
      }

      if (isMounted.current) {
        console.log('setting new forms')
        setState({
          isLoading: false,
          loadError: newError,
          forms: newForms,
        })
      }
    },
    [isMounted, tileFilter],
  )

  React.useEffect(() => {
    const abortController = new AbortController()
    reloadFormsList(abortController.signal)
    return () => {
      abortController.abort()
    }
  }, [isOffline, reloadFormsList])

  const selectForm = React.useCallback(
    async (formId: number) => {
      filterDrafts({
        filter: (draft) => draft.formId === formId && !!draft.draftSubmission,
        ifNoDrafts: () => {
          const path = tileFilter
            ? `/forms/${formId}?parentTile=${tileFilter}`
            : `/forms/${formId}`

          history.push(path)
        },
      })
    },
    [filterDrafts, history, tileFilter],
  )

  return {
    draftsForForm: filteredDrafts,
    loadError,
    isLoading,
    forms,
    isSyncingAndReloadingDrafts,
    actions: {
      selectForm,
      deselectForm: unfilterDrafts,
      clearLoadError,
    },
  }
}
