// @flow

import * as React from 'react'
import { Link } from 'react-router-dom'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import useForm from 'hooks/useForm'
import { MaterialIcon } from 'components'

export type BreadCrumbItem = {
  name: string
  path?: {
    pathname: string
    search?: string
  }
}

function BreadCrumbs() {
  const { form } = useForm()
  const breadCrumbs = useBreadCrumbs(form)

  return (
    <>
      {!!breadCrumbs.length &&
        breadCrumbs.map((item: BreadCrumbItem, index: number) => (
          <React.Fragment key={index}>
            <span className="ob-header-navigation__breadcrumb-chevron-container">
              <MaterialIcon>chevron_right</MaterialIcon>
            </span>
            <span
              className={
                item.path
                  ? 'ob-header-navigation__breadcrumb-link'
                  : 'ob-header-navigation__breadcrumb-active-item'
              }
            >
              {item.path ? (
                <Link to={item.path}>{item.name}</Link>
              ) : (
                <b>{item.name}</b>
              )}
            </span>
          </React.Fragment>
        ))}
    </>
  )
}

export default React.memo(BreadCrumbs)
